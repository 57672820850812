import React, { useState } from 'react';
import { FormLayout, Button, LinkButton, Form, FormItem, Input } from '../components/auth/Form';
import { Alert } from 'antd';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useSignup } from '../features/auth/api/Session';


const StyledSpan = styled.span`
  color: #adadad;
  font-size:16px;
  font-weight: normal;
`

const StyledLink = styled.a`
  color:#1677ff;
`

export default function SignupPage() {

  const navigate = useNavigate();
  const { mutate } = useSignup();
  const [error, setError] = useState(null);

  const onFinish = (data) => {

    const names = data.name ? data.name.split(' ') : [];
    const firstName = names[0] || '';
    const lastName = names[1] || '';

    let nextEmail = data.email ? data.email.trim() :'';
    let nextPassword = data.password ? data.password.trim():'';

    setError(null);

    mutate(
      {
        target: 'organization',
        email: nextEmail,
        password: nextPassword,
        firstName: firstName,
        lastName: lastName,
      },
      {
        onSuccess: (res) => navigate('/templates'),
        onError: (res) => setError(res.response),
      }
    );

  };

  return (
    <FormLayout
      title="Get Started"
      subtitle="Please enter your details to create your free account."
    >
      <Form
        layout="vertical"
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <FormItem
          label="Full Name"
          name="name"
          rules={[{ message: 'Please input your name!' }]}
        >
          <Input placeholder="Jane Doe" />
        </FormItem>
        <FormItem
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <Input placeholder="jane@acme.com" />
        </FormItem>
        <FormItem
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password placeholder="•••••••••" />
        </FormItem>
        <FormItem>
          <LinkButton to="/login" prefix="Already have an account?">
            Login
          </LinkButton>
        </FormItem>
        {!error ? null : (
          <FormItem>
            <Alert message={error.status === 409 ? "Account already exists. Try to login or contact support." : "Oops.. unable to register right now."} type="warning" />
          </FormItem>
        )}
        <FormItem>
          <Button type="primary" htmlType="submit">
            Continue
          </Button>
        </FormItem>
        <FormItem>
          <StyledSpan>By continuing, you agree to the <StyledLink href="https://joyfill.io/terms" target="_blank">Terms of Service</StyledLink> and <StyledLink href="https://joyfill.io/privacy-policy" target="_blank">Privacy Policy</StyledLink></StyledSpan>
        </FormItem>
      </Form>
    </FormLayout>
  );

}
