import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import { Modal, Spin, Space, Button, Dropdown } from 'antd';
import { FileTextOutlined, EllipsisOutlined, SyncOutlined } from '@ant-design/icons';
import { Breadcrumb, BreadcrumbItem, BreadcrumbItemIcon } from '../components/layout/Breadcrumb';
import { Card, CardBody } from '../components/layout/Card';
import { JoyDoc } from '@joyfill/components';
import DocumentTypes from '../constants/DocumentTypes';

import { getAccessToken } from '../lib/axios';
import { notify } from '../lib/toasts'

import { useDocument, useUpdateDocument, useDocumentVersions, useTemplateDocumentSync } from '../features/documents/api/Documents';
import useDocumentFileUploader from '../features/documents/hooks/useDocumentFileUploader';
import DocumentActionBar from '../features/documents/components/DocumentActionBar';
import DocumentVersionList from '../features/documents/components/DocumentVersionList';
import Prompt from '../hooks/useBlock';
import ReloadBlocker from '../components/ReloadBlocker';

const BreadcrumbWrapper = styled.div`
  flex: 1;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BodyWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

const VersionWrapper = styled.div`
  margin-left: 12px;
  max-width: 280px;
`;

const DocumentPage = ({ type }) => {

  const [ searchParams, setSearchParams ] = useSearchParams();
  const { identifier } = useParams();
  const { isLoading, data } = useDocument(identifier);

  const [hasChanges , setHasChanges] = useState(false)
  const [docChanges, setDocChanges] = useState(null);
  const [loading, setLoading] = useState(false);

  const templateDocumentSync = useTemplateDocumentSync();
  const updateDocument = useUpdateDocument();
  const uploader = useDocumentFileUploader();
 
  const groupIdentifier = searchParams.get('group');
  const mode = searchParams.get('mode') || 'edit';
  
  const handleUpload = async ({ identifier, target }, fileUploads) => {
    const links = await uploader({ documentId: identifier, target }, fileUploads)
    return links;
  }

  const handleDocumentUpdate = (nextDoc) => {
    setLoading(true);
    setHasChanges(false)
    updateDocument.mutate(      
      { identifier, payload: nextDoc },
      {
        onSuccess: (data) => {
          notify.success('Success')
          setLoading(false);
          setDocChanges(null);
        },
        onError: () => {
          notify.error('Oops.. update failed')
          setLoading(false);
        },
      }
    );
  }
  
  const handleDocumentChange = (changelogs, doc) => {
    if (activeVersion) return; //Ignore any changes while previewing an active version
    setHasChanges(true)
    setDocChanges(doc);
  }

  /**
   * Start Version Management Handlers
   */
  const [versionsPage, setVersionsPage] = useState(1);
  const [displayVersions, setDisplayVersions] = useState(false);
  const [activeVersion, setActiveVersion] = useState(null);

  const versions = useDocumentVersions(identifier, {page: versionsPage, limit: 12});

  const handlePreviewVersion = (docVersion) => {
    setActiveVersion(docVersion);
  }

  const handleToggleDisplayVersions = (docVersion) => {
    const visible = !displayVersions;
    setDisplayVersions(visible);
    if (!visible) setActiveVersion(null);
  }

  const handleVersionRestore = (docVersion) => {
    const nextVersion = { files: docVersion.files, fields: docVersion.fields, createdOn: new Date().getTime() };
    if (nextVersion._id) delete nextVersion._id;
    handleDocumentUpdate(nextVersion);
    handleToggleDisplayVersions();
  }
  /**
   * End Version Management Handlers
   */

  const [syncing, setSyncing] = useState(false);
  const [displaySyncConfirm, setDisplaySyncConfirm] = useState(false);
  const toggleSyncConfirmModal = () => setDisplaySyncConfirm(!displaySyncConfirm);

  const handleSyncDocuments = () => {
    setDisplaySyncConfirm(false);
    setSyncing(true);
    templateDocumentSync.mutate(      
      { identifier },
      {
        onSuccess: (data) => {
          notify.success('Sync success')
          setSyncing(false);
        },
        onError: () => {
          notify.error('Sync failed')
          setSyncing(false);
        },
      }
    );

  };

  if (isLoading) return <div>Loading...</div>;

  const BreadcrumbNode = groupIdentifier ? (
    <Breadcrumb>
      <BreadcrumbItem>
        <BreadcrumbItemIcon icon={<FileTextOutlined />} />
        <Link tabIndex={-1} to={`/groups/${groupIdentifier}`}>
          Group {data?.type === DocumentTypes.template ? 'Templates' : 'Documents'}
        </Link>
      </BreadcrumbItem>
      <BreadcrumbItem tabIndex={-1}>
        {identifier ? data?.name : 'New Template'}
      </BreadcrumbItem>
    </Breadcrumb>
  ) : (
    <Breadcrumb>
      <BreadcrumbItem>
        <BreadcrumbItemIcon icon={<FileTextOutlined />} />
        <Link tabIndex={-1} to={`/${type}s`}>
          {type === DocumentTypes.template ? 'Templates' : 'Documents'}
        </Link>
      </BreadcrumbItem>
      <BreadcrumbItem tabIndex={-1}>
        {identifier ? data?.name : 'New'}
      </BreadcrumbItem>
    </Breadcrumb>
  );

  return (
    <div>
      <ReloadBlocker shouldBlock={hasChanges} />
      <Modal title={<><Spin size="small"/> &nbsp; Syncing</>} open={syncing} footer={null}>
        Syncing is in progress. Syncing may take a few minutes to complete. This modal will automatically close when completed.
      </Modal>
      <Modal title="Sync Documents Confirmation" open={displaySyncConfirm} okText="Confirm" onOk={handleSyncDocuments} onCancel={toggleSyncConfirmModal}>
        Syncing will apply all the template styles and layouts to all associated documents. Ensure you have saved all template changes before continuing. Are you sure you want to continue?
      </Modal>
      <Space direction="vertical" style={{display: 'flex'}}>
        <StyledHeader>
          <BreadcrumbWrapper>
            {BreadcrumbNode}
          </BreadcrumbWrapper>
          <Space >
            <Button 
              shape="round" 
              onClick={handleToggleDisplayVersions}
            >
              {displayVersions ? 'Hide History' : 'Version History'}
            </Button>
            {type !== DocumentTypes.template ? null : (
              <Dropdown 
                menu={{ 
                  onClick: ({ key }) => {
                    if (key === 'sync') toggleSyncConfirmModal()
                  },
                  items: [
                    {
                      key: 'sync',
                      icon: <SyncOutlined />,
                      label: 'Sync Documents',
                    }
                  ] 
                }} 
                placement="left" 
                arrow={{ pointAtCenter: true }}
              >
                <Button icon={<EllipsisOutlined />} shape="round"/>
              </Dropdown>
            )}
          </Space>
        </StyledHeader>
      </Space>
      <BodyWrapper>
        <Card>
          <CardBody>
            <DocumentActionBar
              display={!!docChanges}
              loading={loading}
              onSubmit={() => handleDocumentUpdate(docChanges)}
            />

            {/* !ALERT - Modifying Me Means Most Likely Modifing /embed -> <JoyDoc .... /> */}
            <JoyDoc
              mode={mode}
              doc={activeVersion || data}
              onChange={handleDocumentChange}
              onUploadAsync={handleUpload}
            />
          </CardBody>
        </Card>
        {!displayVersions ? null : (
          <VersionWrapper>
            <Card>
              <CardBody>
                <DocumentVersionList 
                  page={versionsPage}
                  current={data}
                  loading={versions.isLoading}
                  items={versions?.data?.data}
                  onPreview={handlePreviewVersion}
                  onRestore={handleVersionRestore}
                  onPageChange={setVersionsPage}
                />
              </CardBody>
            </Card>
          </VersionWrapper>
        )}
      </BodyWrapper>
      <Prompt when={hasChanges} message='Changes that you made may not be saved.'/>
    </div>
  ); 
}

export default DocumentPage;

