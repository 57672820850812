import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { post, get, remove } from '../../../lib/axios';

/**
 * React-Query Keys
 */
export const keys = {
  items: (filters) => ['users', filters.page, filters]
};

/**
 * Hooks
 */
export const useUsers = (filters, options = {}) => {
  return useQuery({
    queryKey: keys.items(filters),
    queryFn: () => get(`/v1/users`, filters),
    ...options
  });
};

export const useCreateUser = () => {

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload) => post('/v1/users/invites', payload),
    onSuccess: (resource) => {
      queryClient.invalidateQueries(keys.items)
    }
  });

};

export const useUpdateUser = () => {

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({id, payload}) => post(`/v1/users/${id}`, payload),
    onSuccess: (resource) => {
      queryClient.invalidateQueries(keys.items)
    }
  });

};

export const useRemoveUser = () => {

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => remove(`/v1/users/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries(keys.items)
    }
  });

};

/**
 * React-Query Keys
 */
export const accessTokenKeys = {
  items: (filters) => ['user_tokens', filters.page, filters]
};


export const useUserAccessTokens = (id, filters, options = {}) => {
  return useQuery({
    queryKey: accessTokenKeys.items(filters),
    queryFn: () => get(`/v1/users/${id}/access_tokens`, filters),
    ...options
  });
};


export const useCreateUserAccessToken = () => {

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({id, payload}) => post(`/v1/users/${id}/access_tokens`, payload),
    onSuccess: (resource) => {
      queryClient.invalidateQueries(accessTokenKeys.items)
    }
  });

};

export const useRemoveUserAccessToken = () => {

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({id, token}) => remove(`/v1/users/${id}/access_tokens/${token}`),
    onSuccess: () => {
      queryClient.invalidateQueries(accessTokenKeys.items)
    }
  });

};

export const useCreatePasswordRecoveryToken = () => {

  return useMutation({
    mutationFn: ({ payload }) => post(`/v1/users/passwords/recoveryTokens`, payload),
  })
  
};

export const useUpdatePasswordFromRecoveryToken = () => {

  return useMutation({
    mutationFn: ({ passwordRecoveryToken, payload }) => post(`/v1/users/passwords/recoveryTokens/${passwordRecoveryToken}`, payload),
  })
  
};


const exports = {
  useUsers,
  useCreateUser,
  useUpdateUser,
  useRemoveUser,
  useUserAccessTokens,
  useCreateUserAccessToken,
  useRemoveUserAccessToken
};

export default exports;
