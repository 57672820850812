import { 
  createBrowserRouter, 
  createRoutesFromElements,
  Route,
  Navigate,
  Outlet,
} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import RootPage from './pages/RootPage';
import ErrorPage from './pages/ErrorPage';
import LoginPage from './pages/LoginPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import RegisterPage from './pages/RegisterPage';
import UsersPage from './pages/UsersPage';
import KeysPage from './pages/KeysPage';
import WebhooksPage from './pages/WebhooksPage';
import DocumentsPage from './pages/DocumentsPage';
import DocumentPage from './pages/DocumentPage';
import EmbedPage from './pages/EmbedPage';
import NewTemplatePage from './pages/NewTemplatePage';
import ExportPage from './pages/ExportPage';
import GroupsPage from './pages/GroupsPage';
import GroupPage from './pages/GroupPage';

import { useSession } from './features/auth/api/Session';
import { removeAccessToken } from './lib/axios';
import { gray1 } from './constants/Colors';
import DocumentTypes from './constants/DocumentTypes';
import { Menu } from './components/Menu'

import { Layout } from 'antd';
const { Content } = Layout;

/**
 * Auth flow strategy for react-router-v6 pulled from: 
 * https://blog.logrocket.com/complete-guide-authentication-with-react-router-v6/
 */

/**
 * Auth routes don't require an active user session
 */
export const AuthRouter = () => {

  const { data, isFetched } = useSession();

  if (data && isFetched) {
    return <Navigate to="/templates" />;
  }

  return (
    <Outlet />
  )
};

/**
 * Protected routes don't require an active user session
 */
export const ProtectedRouter = () => {

  const { data, isFetched } = useSession();

  if (!data && isFetched) {
    return <Navigate to="/login" />;
  }

  return (
    <Layout style={{background: gray1}} className="layout">
      <Menu
        onLogout={() => {
          removeAccessToken()
          window.location = '/login'
        }}
      />
      <Content style={{padding: '15px 35px' }}>
        <Outlet />
      </Content>
    </Layout>
  );

};

export default createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<RootPage />}
      errorElement={<ErrorPage />}
    >
      <Route path="/" element={<AuthRouter />}>
        <Route index element={<Navigate to="/login" />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgotPassword" element={<ForgotPasswordPage/>}/>
        <Route path="/resetPassword" element={<ResetPasswordPage/>} />
      </Route>
      
      <Route path="/embed" element={<EmbedPage />} />

      <Route element={<ProtectedRouter />}>

        <Route path="/template" element={<NewTemplatePage type={DocumentTypes.template}/>} />
        <Route path="/templates" element={<DocumentsPage type={DocumentTypes.template}/>} />
        <Route path="/templates/:identifier" element={<DocumentPage type={DocumentTypes.template}/>} />

        <Route path="/documents" element={<DocumentsPage type={DocumentTypes.document}/>} />
        <Route path="/documents/:identifier" element={<DocumentPage type={DocumentTypes.document}/>} />

        <Route path="/groups" element={<GroupsPage />} />
        <Route path="/groups/:identifier" element={<GroupPage />} />
        <Route path="/users" element={<UsersPage />} />
        <Route path="/api_keys" element={<KeysPage />} />
        <Route path="/webhooks" element={<WebhooksPage />} />
      </Route>
      <Route path="/export" element={<ExportPage />} />
    </Route>
  )
);
