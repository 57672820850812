import axios from 'axios';

const localStorageKey = 'access_token';

export const setAccessToken = (token) => {
  window.localStorage.setItem(localStorageKey, token);
}

export const getAccessToken = () => {
  const token = window.localStorage.getItem(localStorageKey);
  return token || '';
}

export const removeAccessToken = () => {
  return window.localStorage.removeItem(localStorageKey);
}

/**
 * Formats headers allowing optional headers
 * @param {String} accessToken valid userAccessToken
 * @returns { <headers> }
 */
export const getHeaders = (accessToken) => {
  return {
    Authorization: `Bearer ${accessToken || getAccessToken()}`
  };
}

/**
 * Axios POST
 *
 * @param {String} url
 * @param {String} accessToken
 * @param {Object} payload
 */
export const post = async (url, payload, accessToken) => {

  const { data } = await axios({
    method: 'post',
    url,
    baseURL: process.env.REACT_APP_API_URL,
    data: payload,
    headers: getHeaders(accessToken)
  });

  return data;

}

/**
 * Axios GET
 *
 * @param {String} url
 * @param {String} accessToken
 * @param {Object} query
 */
export const get = async (url, query, accessToken) => {

  let targetUrl = url;
  let queryString = '';

  if (query) {
    const params = new URLSearchParams(query);
    queryString = `?${params.toString()}`;
    targetUrl = `${url}${queryString}`; 
  }

  const { data } = await axios({
    method: 'get',
    url: targetUrl,
    baseURL: process.env.REACT_APP_API_URL,
    headers: getHeaders(accessToken)
  });

  return data;

}

/**
 * Axios DELETE
 *
 * @param {String} url
 */
export const remove = async (url, accessToken) => {

  const { data } = await axios({
    method: 'delete',
    url,
    baseURL: process.env.REACT_APP_API_URL,
    headers: getHeaders(accessToken)
  });

  return data;

}

export default {
  getHeaders,
  post,
  get,
  remove
}
