import React, { useState } from 'react';
import styled from 'styled-components';
import { Space, Button } from 'antd';
import { PlusOutlined, IdcardOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { notify } from '../lib/toasts'

import { Title } from '../components/layout/Title';
import { Card } from '../components/layout/Card';
import Paginations from '../constants/Pagination';
import { useGroups, useRemoveGroup } from '../features/groups/api/Groups';
import GroupList from '../features/groups/components/GroupList';
import GroupFormModal from '../features/groups/components/GroupFormModal';
import normalizeArray from '../utils/normalizeArray';

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin-bottom: -10px;
`;

const GroupsPage = () => {

  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [displayLoadingForId, setDisplayLoadingForId] = useState(false);
  const [displayCreateModal, setDisplayCreateModal] = useState(false);

  const [activeGroupId, setActiveGroupId] = useState(false);

  const { isLoading, data } = useGroups({ page, limit: Paginations.pageSize }, {keepPreviousData: true});
  const removeGroup = useRemoveGroup();

  if (isLoading) return <h1>Loading</h1>

  const groupsLookup = normalizeArray('identifier', data?.data || []);
  const activeGroup = activeGroupId ? groupsLookup[activeGroupId] : {};

  const handleSelect = (identifier) => {
    navigate(`/groups/${identifier}`);
  };

  const handleDelete = (id) => {

    setDisplayLoadingForId(id)

    removeGroup.mutate(
      id,
      {
        onSuccess: (data) => {
          notify.success('Group removed successfully');
          setDisplayLoadingForId(null)
        },
        onError: () => {
          notify.error('Error removing member');
          setDisplayLoadingForId(null)
        },
      } 
    );

  }

  const handleEdit = (id) => {
    setDisplayCreateModal(true)
    setActiveGroupId(id)
  }


  return (
    <div>
      <Space direction="vertical" style={{display: 'flex'}}>
        <StyledHeader>
          <Title icon={<IdcardOutlined />}>
            Groups 
          </Title>
          <StyledButton 
            type="primary" 
            size="large" 
            onClick={() => setDisplayCreateModal(true)}
            icon={<PlusOutlined />} 
          >
             Add Group
          </StyledButton>
        </StyledHeader>
        <GroupFormModal
          isOpen={displayCreateModal} 
          group={activeGroup}
          submitText={activeGroup && activeGroup.identifier ? "Update Group" : "Create Group"}
          onClose={() => {
            setActiveGroupId(null)
            setDisplayCreateModal(false)
          }}
        />
        <Card>
          <GroupList
            items={data?.data}
            onSelect={handleSelect}
            onDelete={handleDelete}
            onEdit={handleEdit}
            page={page}
            onPageChange={setPage}
          />
        </Card>
      </Space>
    </div>
  ); 
}

export default GroupsPage;

