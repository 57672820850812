import React from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

const RootPage = () => {
  return (
    <>
      {/* Helpers */}
      <ToastContainer />

      {/* Root */}
      <Outlet/>
    </>
  ); 
}

export default RootPage;
