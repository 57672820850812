import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { brandblue, gray5 } from '../../../constants/Colors';

const StyledBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`;

const StyledBar = styled.div`
  border: 1px solid ${gray5};
  padding: 6px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const DocumentActionBar = ({ display, loading, onSubmit }) => {

  if (!display) return null;
  
  return (
    <StyledBarWrapper>
      <StyledBar>
        <Button 
          type="primary"
          shape="round" 
          loading={loading}
          onClick={onSubmit}
          icon={<SaveOutlined />} 
          tabIndex={-1}
        >
          Save 
        </Button>
      </StyledBar>
    </StyledBarWrapper>
  );

}

export default DocumentActionBar;
