import { post } from '../../../lib/axios';

const getDataUriForFileUpload = async (fileUpload) => {

  return new Promise((ful, rej) => {

    const reader = new FileReader();
    reader.readAsDataURL(fileUpload);
    reader.onloadend = async () => {
      ful(reader.result);
    };

  });

};

const convertPDFPagesToBase64Async = async (dataUri) => {
  const response = await post(`/v1/utilities/pdf/to/png/datauri`, {file: dataUri})
  return response;
};

const uploadFileAsync = async (identifier, dataUri) => {
  const response = await post(`/v1/documents/${identifier}/files/datauri`, {file: dataUri})
  return response;
};


const useDocumentFileUploader = () => {

  return async ({ documentId, target }, fileUploads) => {

    /**
     * Why do we do this?
     * The changelog field types for file / page / field Uploads
     * appear as field.update  / file.update/ page.update. So
     * we need to split that up to match the type of file being uploaded
     */

    const uploadTarget = target.split('.')[0];

    if (uploadTarget === 'file' || uploadTarget === 'page') {

      if (fileUploads[0].type === 'application/pdf') {

        const dataUri = await getDataUriForFileUpload(fileUploads[0]);
        const base64DataUris = await convertPDFPagesToBase64Async(dataUri);
        const fileUploadPromises = base64DataUris?.items?.map(({ url }) => uploadFileAsync(documentId, url));

        return Promise.all(fileUploadPromises);

      } else {
        const dataUri = await getDataUriForFileUpload(fileUploads[0]);
        const uploadResult = await uploadFileAsync(documentId, dataUri);
        return [uploadResult]

      }

    } else if (uploadTarget === 'field') {

      const uploads = Array.isArray(fileUploads) ? fileUploads : [fileUploads];

      const fileUploadPromises = uploads.map(async (fileUpload) => {

        /**
         * ---------NOTICE------------
         * If multiple image uploads is enabled on the image field, you need
         * to append the newly uploaded images to the list of exisiting images.
         * 
         * Exisitng images will already have an url associated with them so
         * it's enough if you just return it 
        */
  
        if(fileUpload && fileUpload.url) return fileUpload;

        const dataUri = await getDataUriForFileUpload(fileUpload);
      
        return uploadFileAsync(documentId, dataUri)
      
      });

      return Promise.all(fileUploadPromises);

    }


  }

};


export default useDocumentFileUploader;
