import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Popconfirm, Table, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import SimplePagination from '../../../components/SimplePagination';
import Paginations from '../../../constants/Pagination';


const StyledActions = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const KeyList = ({
  items = [],
  loadingItemId,
  onDelete,
  page,
  onPageChange
}) => {

  const dataSource = items.map((item) => {
    return {
      key: item._id,
      id: item._id,
      type: item.type,
      name: item.name,
      keyPublic: item.keyPublic,
      keySecretPreview: `${item.keySecretPreview}**************************`,
      created: dayjs(item.created).format('MM-DD-YYYY'),
    }
  });

  return (
    <>
      <Table 
        size="middle"
        pagination={false}
        dataSource={dataSource} 
        columns={[
          {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (text, record, index) => {
              return text;
            }
          },
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record, index) => {
              return text;
            }
          },
          {
            title: 'Public Key',
            dataIndex: 'keyPublic',
            key: 'keyPublic',
            render: (text, record, index) => {
              return text;
            }
          },
          {
            title: 'Secret Key',
            dataIndex: 'keySecretPreview',
            key: 'keySecretPreview',
            render: (text, record, index) => {
              return text;
            }
          },
          {
            title: 'Created On',
            dataIndex: 'created',
            key: 'created',
            render: (text, record, index) => {
              return text;
            }
          },
          {
            title: '',
            dataIndex: 'actions',
            key: 'id',
            render: (id, record, index) => {
              return (
                <StyledActions>
                  <Popconfirm
                    placement="topLeft"
                    title="Confirm Deletion"
                    description="This action cannot be undone."
                    onConfirm={() => onDelete(record.id)}
                    okText="Delete"
                    cancelText="Cancel"
                  >
                    <Button 
                      type="primary" 
                      shape="circle" 
                      ghost 
                      danger 
                      icon={<DeleteOutlined />} 
                      loading={loadingItemId === record.id}
                    />
                  </Popconfirm>
                </StyledActions>
              );
            }
          }
        ]} 
      />
       <SimplePagination 
        page={page}
        onChange={onPageChange}
        disabled={items.length < Paginations.pageSize}
      />
    </>
  );
 
}

export default KeyList;

