import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Popconfirm, Space, Table as ATable, Button } from 'antd';
import { DeleteOutlined, EditOutlined, FileAddOutlined } from '@ant-design/icons';

import { orange5, green6 } from '../../../constants/Colors';
import TemplateStages from '../../../constants/TemplateStages';
import Paginations from '../../../constants/Pagination';
import SimplePagination from '../../../components/SimplePagination';
import DocumentTypes from '../../../constants/DocumentTypes';

const StyledActions = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Table = styled(ATable)`
  tr {
    cursor: pointer;
  }
`;

const DocumentList = ({
  loading = false,
  items = [],
  loadingItemId,
  onEdit,
  onView,
  onFill,
  onDelete,
  page = 1,
  onPageChange,
  filter
}) => {

  const dataSource = items.map((item) => {
    return {
      key: item.identifier,
      id: item.identifier,
      name: item.name,
      stage: item.stage === TemplateStages.draft ? 'Draft' : 'Published',
      createdOn: dayjs(item.createdOn).format('MM/DD/YYYY hh:mma')
    }
  });

  const handleChange = (changes) => {
    if (changes.current) onPageChange(changes.current)
  }

  const getAction = (record, docFilter) => {

    return (
      <>
        {docFilter !== DocumentTypes.template ? null : ( 
            <>
              <Button 
                shape="round" 
                onClick={() => {
                  onEdit(record.id)
                }}
                icon={<EditOutlined />} 
              >
                Edit
              </Button>
              <Button 
                shape="round" 
                onClick={() => {
                  onFill(record.id)
                }}
                icon={<FileAddOutlined />} 
              >
                Fill Out
              </Button>
            </>
          )
        }
        {docFilter !== DocumentTypes.document ? null : ( 
            <Button 
              shape="round" 
              onClick={() => {
                onView(record.id)
              }}
            >
              View 
            </Button>
          )
        }

      </>
    );
  }

  return (
    <>
      <Table 
        size="middle"
        loading={loading}
        dataSource={dataSource} 
        onChange={handleChange}
        pagination={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              event.preventDefault();
            },
          };
        }}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record, index) => {
              return text;
            }
          },
          {
            title: 'Stage',
            dataIndex: 'stage',
            key: 'stage',
            render: (text, record, index) => {
              return text === 'Draft' ? 
                (<span style={{fontWeight: 500, color: orange5 }}>{text}</span>) :
                (<span style={{fontWeight: 500, color: green6 }}>{text}</span>);
            }
          },
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => {
              return text;
            }
          },
          {
            title: 'Created',
            dataIndex: 'createdOn',
            key: 'createdOn',
            render: (text, record, index) => {
              return text;
            }
          },
          {
            title: '',
            dataIndex: 'actions',
            key: 'id',
            render: (id, record, index) => {
              return (
                <StyledActions>
                  <Space>
                    {getAction(record, filter)}
                    <Popconfirm
                      placement="topLeft"
                      title="Confirm Deletion"
                      description="This action cannot be undone."
                      onConfirm={() => onDelete(record.id)}
                      okText="Delete"
                      cancelText="Cancel"
                    >
                      <Button 
                        type="primary" 
                        shape="circle" 
                        ghost 
                        danger 
                        loading={loadingItemId === record.id}
                        icon={<DeleteOutlined />} 
                      />
                    </Popconfirm>
                  </Space>
                </StyledActions>
              );
            }
          }
        ]} 
      />
      <SimplePagination
        page={page}
        onChange={onPageChange}
        disabled={items.length < Paginations.pageSize}
      />
    </>
  ); 
}

export default DocumentList;
