import { 
  useQueryClient, 
  useQuery,
  useMutation,
  QueryClient
} from '@tanstack/react-query';

import { setAccessToken, post, get, remove } from '../../../lib/axios';

/**
 * React-Query Keys
 */
const keys = {
  item: ['session'],
  org: ['organization']
};

export const useSignup = () => {

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload) => post(`/v1/users/sessions/registration`, payload),
    onSuccess: (session) => {

      setAccessToken(session.token); 

      const nextSession = {...session}
      delete nextSession.token

      queryClient.setQueryData(keys.item, nextSession)

    }
  });

};

export const useLogin = () => {

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload) => post(`/v1/users/sessions`, payload),
    onSuccess: (session) => {
      
      if (session && session.organization) {

        setAccessToken(session.token);

        const nextSession = { ...session }

        delete nextSession.token

        queryClient.setQueryData(keys.item, nextSession);

      }

    }
  });

};

export const useOrganization = () => {
  return useQuery({
    queryKey: keys.org,
    queryFn: () => get('/v1/organizations/web_sessions'),
  });
};

export const useSession = () => {
  return useQuery({
    queryKey: keys.item,
    queryFn: () => get('/v1/users/sessions'),
  });
};


export default {
  useSignup,
  useLogin,
  useSession
}
