/**
 * Normalize an array of objects
 *
 * @param {Array} array 
 * @param {String|Number} key
 * @param {Function} keyExtractor //optional
 *
 * Example Input:
 * param key = 'name';
 * param array = [{name: 'frank', age: 19}, {name: 'joe', age: 22}, {name: 'bob', age: 44}]
 *
 * Example return: 
 * {
 *   'frank': {name: 'frank', age: 19} ,
 *   'joe': {name: 'joe', age: 22} ,
 *   'bob': {name: 'bob', age: 44} ,
 * }
 */
export default function normalizeArray(key, array, keyExtractor) {

  let obj = {};
  
  array.forEach((e) => {

    if (!e[key]) return;

    const objectKey = typeof keyExtractor === 'function' ?
      keyExtractor(e) : e[key];

    obj[objectKey] = e; 

  });

  return obj;

}
