import React, { useState } from 'react';
import styled from 'styled-components';
import { Popconfirm, Input, Space, Table, Button, Badge } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { notify } from '../../../lib/toasts'

import { EMAIL_REQEX } from '../../../constants/FormValidators'
import Paginations from '../../../constants/Pagination';

import SimplePagination from '../../../components/SimplePagination';

const StyledActions = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const UserList = ({
  sessionItemId,
  activeItemId,
  sessionId,
  items = [],
  submitLoading,
  submitText,
  onSubmit,
  onDelete,
  onSelect,
  onTokenSelect,
  page,
  onPageChange,
}) => {

  const [form, setForm] = useState({});

  const handleChange = (field, value) => {
    const nextForm = { ...form, [field]: value };
    setForm(nextForm);
  }

  const handleUpdate = (userIdentifier) => {

    const updatePayload = { ...form };
    delete updatePayload._id;

    if (updatePayload.name) {
      const names = updatePayload.name.trim().split(' ');
      updatePayload.firstName = names[0] || '';
      updatePayload.lastName = names[1] || '';
      delete updatePayload.name;
    }

    //Validate email
    if (!form.email) delete updatePayload.email
    else if (!form.email.toLowerCase().match(EMAIL_REQEX)) return notify.warning('Must be a valid email address');

    //Validate password
    if (!form.password) delete updatePayload.password
    else if (form.password.length < 6) return notify.warning('Password must be atleast 6 characters long');

    if (userIdentifier) onSubmit(userIdentifier, updatePayload);

  }

  const dataSource = items.map((item) => {
    return {
      key: item._id,
      id: item.identifier,
      identifier: item.identifier,
      name: `${item.firstName || ''} ${item.lastName || ''}`,
      email: item.email,
      password: '*************',
      self: sessionId === item._id
    }
  });

  return (
    <>
      <Table
        size="middle"
        pagination={false}
        dataSource={dataSource}
        columns={[
          {
            title: 'Full Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record, index) => {
              if (activeItemId !== record.id) {
                return text;
              } else {
                return (
                  <Input
                    style={{ width: '280px' }}
                    value={form.name}
                    onChange={(e) => handleChange('name', e.target.value)}
                  />
                );
              }
            }
          },
          {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (text, record, index) => {
              if (activeItemId !== record.id) {
                return text;
              } else {
                return (
                  <Input
                    style={{ width: '280px' }}
                    value={form.email}
                    onChange={(e) => handleChange('email', e.target.value)}
                  />
                );
              }
            }
          },
          {
            title: 'Password',
            dataIndex: 'password',
            key: 'password',
            render: (text, record, index) => {
              if (activeItemId !== record.id) {
                return text;
              } else {
                return (
                  <Input.Password
                    style={{ width: '280px' }}
                    value={form.password}
                    onChange={(e) => handleChange('password', e.target.value)}
                  />
                );
              }
            }
          },
          {
            title: '',
            dataIndex: 'actions',
            key: 'id',
            render: (id, record, index) => {
              return (
                <StyledActions>
                  <Space>
                    {!record.self ? null : (
                      <Badge 
                        color="palegreen"
                        text="You"
                        style={{
                          marginRight: 5,
                          paddingRight: 12,
                          borderRadius: 25,
                          paddingTop: 1,
                          paddingBottom: 2,
                          backgroundColor: 'palegreen',
                          color: 'green'
                        }}
                      />
                    )}
                    {!record.self ? null : (
                      <Button
                        shape="round"
                        onClick={() => onTokenSelect(record.id)}
                      >
                        Access Tokens
                      </Button>
                    )}
                    {activeItemId === record.id ? (
                      <Button
                        type="primary"
                        shape="round"
                        loading={submitLoading === record.id}
                        disabled={submitLoading === record.id}
                        onClick={() => handleUpdate(record.identifier)}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button
                        shape="round"
                        onClick={() => {
                          setForm({ name: record.name, email: record.email, password: '' })
                          onSelect(record.id)
                        }}
                        icon={<EditOutlined />}
                      >
                        Edit
                      </Button>
                    )}
                    {activeItemId !== record.id ? null : (
                      <Button
                        shape="round"
                        onClick={() => {
                          setForm({})
                          onSelect(null);
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                    {activeItemId === record.id || sessionItemId === record.id ? null : (
                      <Popconfirm
                        placement="topLeft"
                        title="Confirm Deletion"
                        description="This action cannot be undone."
                        onConfirm={() => onDelete(record)}
                        okText="Delete"
                        cancelText="Cancel"
                      >
                        <Button
                          type="primary"
                          shape="circle"
                          ghost
                          danger
                          loading={submitLoading === record.id}
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>
                    )}
                  </Space>
                </StyledActions>
              );
            }
          }
        ]}
      />
      <SimplePagination
        page={page}
        onChange={onPageChange}
        disabled={items.length < Paginations.pageSize}
      />
    </>
  );
}

export default UserList;
