/**
 * Role Types:
 *
 * admin - They can do everything
 * contributor - They can do everything, except modify users and templates
 * restricted   
 * readyOnly - They can see stuff but can't do anything.
 */
const roles = {
  admin: 'admin',
  contributor: 'contributor',
  restricted: 'restricted',
  readOnly: 'readOnly',
};

roles.all = Object.keys(roles).map((key) => roles[key]);

export default roles;
