import React from 'react';
import { useQuery } from '../hooks/useQuery';

import FeatureTypes from '../constants/FeatureTypes';
import Document from '../features/embed/Document';

const AuthError = () => {
  console.error('Joyfill Embed Error: Joyfill JS SDK requires a valid feature.');

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, feature was not configured.</p>
    </div>
  );
}

/**
 * Used as a standalone route to multiple
 * embedded solutions
 */
const EmbedPage = () => {

  const query = useQuery();
  const feature = query.get("feature");
  let featureNode = <Document />

  if (feature === FeatureTypes.document) featureNode = <Document /> 

  return featureNode || <AuthError />
}

export default EmbedPage;
