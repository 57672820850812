import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { post, get, remove } from '../../../lib/axios';

/**
 * React-Query Keys
 */
const keys = {
  items: (filters) => ['keys', filters.page, filters]
};

/**
 * Hooks
 */
export const useKeys = (filters) => {
  return useQuery({
    queryKey: keys.items(filters),
    queryFn: () => get(`/v1/keys`, filters),
  });
};

export const useCreateKey = () => {

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload) => post('/v1/keys', payload),
    onSuccess: (resource) => {
      queryClient.invalidateQueries(keys.items)
    }
  });

};

export const useRemoveKey = () => {

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => remove(`/v1/keys/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries(keys.items)
    }
  });

};


const exports = {
  useKeys,
  useCreateKey,
  useRemoveKey,
};

export default exports;

