import React, { useState } from 'react';
import styled from 'styled-components';
import { Input, Button, Space } from 'antd';
import { notify } from '../../../lib/toasts'

const StyledWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const StyledFormWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const StyledFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledFieldLabel = styled.label`
  font-weight: 500;
`;

const UserForm = ({ 
  submitLoading,
  submitText = "Save", 
  onSubmit, 
  onCancel, 
}) => {

  const [ form, setForm ] = useState({});

  const handleChange = (field, value) => {
    const nextForm = {...form, [field]: value};
    setForm(nextForm);
  }


  const handleSubmit = () => {

    const payload = {...form};

    if (payload.name) {
      const names = payload.name.trim().split(' ');
      payload.firstName = names[0] || '';
      payload.lastName = names[1] || '';
      delete payload.name;
    }

    if (!payload.email || !payload.password) return notify.error('Error: Missing required user fields')

    onSubmit(payload)

  };

  return (
    <StyledWrapper>
      <StyledFormWrapper>
        <Space>
          <StyledFieldWrapper>
            <StyledFieldLabel>Name</StyledFieldLabel>
            <Input 
              style={{width: '280px'}}
              value={form.name}
              onChange={(e) => handleChange('name', e.target.value)}
            />
          </StyledFieldWrapper>
          <StyledFieldWrapper>
            <StyledFieldLabel>Email</StyledFieldLabel>
            <Input 
              style={{width: '280px'}}
              value={form.email}
              onChange={(e) => handleChange('email', e.target.value)}
            />
          </StyledFieldWrapper>
          <StyledFieldWrapper>
            <StyledFieldLabel>Password</StyledFieldLabel>
            <Input.Password
              style={{width: '280px'}}
              value={form.password}
              onChange={(e) => handleChange('password', e.target.value)}
            />
          </StyledFieldWrapper>
        </Space>
      </StyledFormWrapper>
      <Space>
        <Button 
          type="primary" 
          shape="round" 
          size="middle" 
          disabled={submitLoading || !form.email || !form.password}
          loading={submitLoading}
          onClick={handleSubmit}
        >
          Create 
        </Button>
        <Button 
          shape="round" 
          onClick={() => {
            setForm({});
            onCancel()
          }}
        >
          Cancel
        </Button>
      </Space>
    </StyledWrapper>
  );

}

export default UserForm;
