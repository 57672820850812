import React, { useState } from 'react';
import styled from 'styled-components';
import { Popconfirm, Input, Space, Table, Button, Badge } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { notify } from '../../../lib/toasts'

import SimplePagination from '../../../components/SimplePagination';

import { useUserAccessTokens, useCreateUserAccessToken, useRemoveUserAccessToken } from '../api/Users';
import Paginations from '../../../constants/Pagination';

const StyledActions = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const StyledButton = styled(Button)`
  margin-bottom: 12px;
`;


const StyledText = styled.div`
  display: flex;
  flex: 1;
  max-width: 600px;
  white-space: nowrap;
  overflow-x: scroll;
`;


const UserAccessTokenList = ({
  userIdentifier,
}) => {

  const [page, setPage] = useState(1);
  const { isLoading, data } = useUserAccessTokens(userIdentifier, { page, limit: 25 });
  const removeUserAccessToken = useRemoveUserAccessToken();
  const createUserAccessToken = useCreateUserAccessToken();

  const handleCreate = () => {

    createUserAccessToken.mutate(
      { id: userIdentifier, payload: {} },
      {
        onSuccess: (data) => {
          notify.success('Access token successfully added')
        },
        onError: () => {
          notify.error('Oops.. Unable to create access token')
        },
      }
    );
  }

  const handleDelete = (token) => {

    removeUserAccessToken.mutate(
      { id: userIdentifier, token },
      {
        onSuccess: (data) => {
          notify.success('Access token removed successfully');
        },
        onError: () => {
          notify.error('Error removing access token');
        },
      } 
    );

  }

  const dataSource = data?.data?.map((item) => {
    return {
      key: item.token,
      id: item.token,
      token: item.token,
    }
  });

  return (
    <>
      <StyledActions>
        <StyledButton
          type="primary"
          shape="round"
          size="medium"
          onClick={handleCreate}
          icon={<PlusOutlined />}
        >
          Add Access Token
        </StyledButton>
      </StyledActions>
      <Table
        size="middle"
        pagination={false}
        dataSource={dataSource}
        columns={[
          {
            title: '',
            dataIndex: 'token',
            key: 'token',
            render: (text, record, index) => {
              return <StyledText>{text}</StyledText>
            }
          },
          {
            title: '',
            dataIndex: 'actions',
            key: 'id',
            render: (id, record, index) => {
              return (
                <StyledActions>
                  <Space>
                    <Button
                      type="primary"
                      shape="round"
                      ghost
                      onClick={async () => {
                        const result = await navigator.clipboard.writeText(record.token)
                        notify.success('Copied!')
                      }}
                    >
                      Copy
                    </Button>
                    <Popconfirm
                      placement="topLeft"
                      title="Confirm Deletion"
                      description="This action cannot be undone."
                      onConfirm={() => handleDelete(record.token)}
                      okText="Delete"
                      cancelText="Cancel"
                    >
                      <Button
                        type="primary"
                        shape="circle"
                        ghost
                        danger
                        icon={<DeleteOutlined />}
                      />
                    </Popconfirm>
                  </Space>
                </StyledActions>
              );
            }
          }
        ]}
      />
      <SimplePagination
        page={page}
        onChange={setPage}
        disabled={data?.data?.length < Paginations.pageSize}
      />
    </>
  );
}

export default UserAccessTokenList;
