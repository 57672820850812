import React, { useState } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Input, Spin, Popconfirm, Space, Table as ATable, Button } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { duplicate } from '@joyfill/components';

import { orange5, green6 } from '../../../constants/Colors';
import TemplateStages from '../../../constants/TemplateStages';
import Paginations from '../../../constants/Pagination';
import DocumentTypes from '../../../constants/DocumentTypes';

import SimplePagination from '../../../components/SimplePagination';
import { Card } from '../../../components/layout/Card';

import { notify } from '../../../lib/toasts'

import { useDocuments, useCreateDocument } from '../api/Documents';

const StyledActions = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Table = styled(ATable)`
  tr {
    cursor: pointer;
  }
`;

const StyledSearchInput = styled(Input)`
  width: 400px;
  margin-bottom: 14px;
`;

const JoyfillTemplatesList = ({
  groupIdentifier,
  onCreateSuccess,
}) => {

  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [creatingTemplateId, setCreatingTemplateId] = useState('');


  const templateParams = {
    page,
    limit: 10,
    type: DocumentTypes.template,
    stage: 'all'
  };

  if (searchQuery) templateParams.name = { '$regex': searchQuery, '$options': 'i' };

  const {
    isLoading,
    isRefetching,
    data,
    refetch
  } = useDocuments(templateParams, { keepPreviousData: true });

  const dataSource = [];
  const templatesLookup = {};

  if (data?.data) {

    data?.data.forEach((item) => {

      dataSource.push({
        key: item.identifier,
        id: item.identifier,
        name: item.name,
        stage: item.stage === TemplateStages.draft ? 'Draft' : 'Published',
        createdOn: dayjs(item.createdOn).format('MM/DD/YYYY hh:mma')
      });

      templatesLookup[item.identifier] = item;

    });

  }


  const { mutate: createDocument } = useCreateDocument();

  const handleCreate = (template) => {

    const nextTemplate = duplicate(template, { type: DocumentTypes.template, stage: TemplateStages.draft })
    if (groupIdentifier) nextTemplate.group = groupIdentifier;

    createDocument(nextTemplate, {
      onSuccess: (data) => {
        if (onCreateSuccess) onCreateSuccess(data);
        notify.success('Success');
        setCreatingTemplateId('')
      },
      onError: () => {
        notify.error('Oops.. unable to add template')
        setCreatingTemplateId('')
      }
    });

  }

  let searchTimeout;

  return (
    <Card>
      <StyledSearchInput 
        size="large" 
        addonBefore={<SearchOutlined />} 
        placeholder="Search name" 
        onChange={(e) => {
          if (searchTimeout) clearTimeout(searchTimeout);
          searchTimeout = setTimeout(() => {
            setPage(1)
            setSearchQuery(e.target.value.trim());
          }, 500)
        }}
      />
      <Spin spinning={isLoading || isRefetching} delay={100}>
        <Table 
          size="middle"
          loading={isLoading}
          dataSource={dataSource} 
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                event.preventDefault();
              },
            };
          }}
          columns={[
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
              render: (text, record, index) => {
                return text;
              }
            },
            {
              title: 'Stage',
              dataIndex: 'stage',
              key: 'stage',
              render: (text, record, index) => {
                return text === 'Draft' ? 
                  (<span style={{fontWeight: 500, color: orange5 }}>{text}</span>) :
                  (<span style={{fontWeight: 500, color: green6 }}>{text}</span>);
              }
            },
            {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
              render: (text, record, index) => {
                return text;
              }
            },
            {
              title: 'Created',
              dataIndex: 'createdOn',
              key: 'createdOn',
              render: (text, record, index) => {
                return text;
              }
            },
            {
              title: '',
              dataIndex: 'actions',
              key: 'id',
              render: (id, record, index) => {
                return (
                  <StyledActions>
                      <Button 
                        loading={creatingTemplateId === record.id}
                        shape="round" 
                        onClick={() => {
                          setCreatingTemplateId(record.id);
                          handleCreate(templatesLookup[record.id])
                        }}
                        icon={<PlusOutlined />} 
                      >
                        Add Template 
                      </Button>
                  </StyledActions>
                );
              }
            }
          ]} 
        />
        <SimplePagination
          page={page}
          onChange={setPage}
          disabled={dataSource.length < 10}
        />
      </Spin>
    </Card>
  ); 
}

export default JoyfillTemplatesList;
