import React, { useEffect } from "react";

const ReloadBlocker = ({ shouldBlock = false }) => {

    const beforeUnloadListener = (event) => {
        event.preventDefault();
        return event.returnValue = "";
    };
   //Use window EventListener
    useEffect(() => {
        if (shouldBlock) {
            window.addEventListener("beforeunload", beforeUnloadListener)
        }
        return () => window.removeEventListener("beforeunload", beforeUnloadListener)
    }, [shouldBlock])
    return null
}

export default ReloadBlocker