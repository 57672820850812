import { Empty } from 'antd';
import React from 'react';
import { AppPortal } from "svix-react";

import "svix-react/style.css";

const WebhookEmbed = ({ embedLink }) => {

  return (
    <>
      {embedLink 
        ? <AppPortal url={embedLink} /> 
        : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Enabled Webhooks" />
      }
    </>
  ); 
}

export default WebhookEmbed;

