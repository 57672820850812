import React from "react"

const SvgComponent = props => (
    <svg width="286px" height="306px" viewBox="0 0 286 306" version="1.1">
        <g id="Misc" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="joyfill-logo-icon">
                <rect id="Rectangle" fill="#276FFA" x="0" y="0" width="286" height="306" rx="35"></rect>
                <path d="M147.816514,84 L173,103.244983 C153.599388,123.655076 143.899083,147.796145 143.899083,175.66819 C143.899083,203.540235 153.599388,227.681304 173,248.091396 L147.816514,268 C123.938838,237.635605 112,206.858335 112,175.66819 C112,144.478044 123.938838,113.921981 147.816514,84 Z" id="Rectangle-Copy-45" fill="#FFFFFF" fill-rule="nonzero" transform="translate(142.500000, 176.000000) rotate(-90.000000) translate(-142.500000, -176.000000) "></path>
            </g>
        </g>
    </svg>
)

export default SvgComponent
