import React, { useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Dropdown, Modal, Space, Button, Radio, Spin, Row, Col, Tooltip } from 'antd';
import { PlusOutlined, QuestionCircleTwoTone, IdcardOutlined, FileTextOutlined, FileSearchOutlined, FileOutlined } from '@ant-design/icons';
import { getDocumentFromTemplate } from '@joyfill/components';

import { Breadcrumb, BreadcrumbItem, BreadcrumbItemIcon } from '../components/layout/Breadcrumb';
import { Card, CardBody } from '../components/layout/Card';
import DocumentsList from '../features/documents/components/DocumentsList';
import JoyfillTemplatesList from '../features/documents/components/JoyfillTemplatesList';
import TemplateList from '../features/documents/components/TemplateList';
import DocumentOptionsModal from '../features/documents/components/DocumentOptionsModal';
import Paginations from '../constants/Pagination';
import DocumentTypes from '../constants/DocumentTypes';
import normalizeArray from '../utils/normalizeArray';
import { getAccessToken } from '../lib/axios';
import { notify } from '../lib/toasts'
import { useGroup, useUpdateGroup } from '../features/groups/api/Groups';
import { useCreateDocument, useDocuments, useRemoveDocument } from '../features/documents/api/Documents';

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledBreadcrumbsWrapper = styled.div`
  display: flex;
  flex: 1;
`;

const StyledDropdown = styled(Dropdown)`
  margin-bottom: -10px;
  float: right;
`;



const GroupPage = () => {

  const { identifier } = useParams();

  const navigate = useNavigate();
  const [displayTemplates, setDisplayTemplates] = useState(false);
  const [displayJoyfillTemplates, setDisplayJoyfillTemplates] = useState(false);
  const [displayLoadingForId, setDisplayLoadingForId] = useState(false);
  const [activeTemplateId, setActiveTemplateId] = useState(false);
  const [page, setPage] = useState(1);
  const [activeFilter, setActiveFilter] = useState(DocumentTypes.template);
  const group = useGroup(identifier);
  const updateGroup = useUpdateGroup();
  const { mutate: createDocument } = useCreateDocument();
  const docParams = {
    page,
    limit: Paginations.pageSize,
    type: activeFilter,
    stage: 'all',
    group: identifier
  };
  const {
    isLoading,
    isRefetching,
    data,
    refetch
  } = useDocuments(docParams, { keepPreviousData: true });

  const removeDocument = useRemoveDocument();

  if (group.isLoading) return <div>Loading...</div>

  const templatesLookup = normalizeArray('identifier', data?.data || []);

  const handleTemplateCreate = () => navigate(`/template?group=${identifier}`);

  const handleTemplateSelect = (id) => {
    setActiveTemplateId(id);
  };

  const handleDocumentDelete = (id) => {

    setDisplayLoadingForId(id);

    removeDocument.mutate(
      id,
      {
        onSuccess: (data) => {
          setDisplayLoadingForId(null)
        },
        onError: () => {
          setDisplayLoadingForId(null)
        },
      }
    );

  }


  const handleFilterType = ({ target: { value } }) => {
    setActiveFilter(value);
    docParams.type = value;
    refetch(docParams);
  }

  const handleGroupUpdate = (payload) => {

    updateGroup.mutate(
      { identifier, payload },
      {
        onSuccess: (data) => {
          notify.success('Success')
        },
        onError: () => {
          notify.error('Oops.. update failed')
        },
      }
    );

  }

  const createSuccess = (data) => {
    notify.success('Success');
    let successUrl = `/documents/${data.identifier}?group=${identifier}&mode=fill`;
    navigate(successUrl);
  }

  /**
   * Fill Out: Creates a document from a template
   */
  const handleFilloutCreate = (templateIdentifer) => {
    const nextDoc = getDocumentFromTemplate(templatesLookup[templateIdentifer]);
    nextDoc.group = identifier
    createDocument(nextDoc, {
      onSuccess: createSuccess,
      onError: () => notify.error('Oops.. create failed')
    });
  }

  return (
    <div>
      <Space direction="vertical" style={{ display: 'flex' }}>
        <StyledHeader>
          <StyledBreadcrumbsWrapper>
            <Breadcrumb>
              <BreadcrumbItem>
                <BreadcrumbItemIcon icon={<IdcardOutlined />} />
                <Link to="/groups">
                  Groups
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                {group?.data?.title || group?.data?.identifier}
              </BreadcrumbItem>
            </Breadcrumb>
          </StyledBreadcrumbsWrapper>
        </StyledHeader>

      </Space>
      <DocumentOptionsModal
        isOpen={activeTemplateId}
        template={templatesLookup[activeTemplateId]}
        onClose={() => setActiveTemplateId(null)}
        onNavigate={(identifier) => navigate(`/templates/${identifier}?group=${group?.data?.identifier}`)}
        onFill={handleFilloutCreate}
      />
      <Row>
        <Col span={12}>
          <Radio.Group
            buttonStyle="solid"
            size="large"
            value={activeFilter}
            onChange={handleFilterType}
          >
            <Radio.Button value={DocumentTypes.template}>Templates</Radio.Button>
            <Radio.Button value={DocumentTypes.document}>Filled Documents</Radio.Button>
          </Radio.Group>
          <Tooltip title="Click icon to learn the difference between templates and documents">
            <Button
              shape="circle"
              type="link"
              href='https://docs.joyfill.io/docs/key-terminology#template-vs-document'
              target="blank"
              icon={<QuestionCircleTwoTone style={{ fontSize: 17 }} />}
            />
          </Tooltip>
        </Col>
        <Col span={12}>
          {activeFilter !== DocumentTypes.template ? null : (
            <StyledDropdown 
              menu={{ 
                items: [
                  {label: 'Blank', key: 'blank', icon: <FileOutlined/>}, 
                  {label: 'Templates', key: 'templates', icon: <FileTextOutlined/>}, 
                  {label: 'Joyfill Templates', key: 'joyfill', icon: <FileSearchOutlined />}
                ],
                onClick: (e) => {
                  if (e.key === 'blank') handleTemplateCreate();
                  else if (e.key === 'joyfill') setDisplayJoyfillTemplates(true);
                  else if (e.key === 'templates') setDisplayTemplates(true);
                }
              }}
            >
              <Button
                type="primary"
                size="large"
              >
                Add Template
                <PlusOutlined />
              </Button>
            </StyledDropdown>
          )}
        </Col>
      </Row>
      <p />
      <Card>
        <Spin spinning={isLoading || isRefetching} delay={100}>
          <DocumentsList
            items={data?.data}
            loadingItemId={displayLoadingForId}
            filter={activeFilter}
            page={page}
            onPageChange={setPage}
            onEdit={handleTemplateSelect}
            onView={(identifier) => navigate(`/documents/${identifier}?group=${group?.data?.identifier}&mode=fill`)}
            onDelete={handleDocumentDelete}
            onFill={handleFilloutCreate}
          />
        </Spin>
      </Card>
      <Modal 
        title="Your Templates" 
        open={displayTemplates}
        footer={null}
        width="60%"
        onCancel={() => setDisplayTemplates(false)}
      >
        <TemplateList groupIdentifier={identifier}/>
      </Modal>
      <Modal 
        title="Joyfill Templates" 
        open={displayJoyfillTemplates}
        footer={null}
        width="60%"
        onCancel={() => setDisplayJoyfillTemplates(false)}
      >
        <JoyfillTemplatesList groupIdentifier={identifier}/>
      </Modal>
    </div>
  );
}

export default GroupPage;

