import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  Form as AForm,
  Input as AInput,
  Card,
  Row,
  Col,
  Layout as ALayout,
  Button as AButton
} from 'antd';
import { Title, Text } from './Text';
import { ReactComponent as Logo } from '../../assets/logo_text.svg';

export const Input = styled(AInput)`
  border-radius: 8px;
  padding: 10px;
  font-size: 18px;
`

export const FormItem = styled(AForm.Item)`
  margin-bottom: 20px;

  .ant-input-password {
    border-radius: 8px;
    padding: 10px;
    font-size: 18px;
  }
  
  .ant-form-item-required::before {
    display: none !important;
  }
`

export const Form = styled(AForm)`
  margin-top: 40px;
`

export const FormWrapper = styled(Card)`
  width: 75%;
  padding: 24px 5%;
  border: 0px;
  box-shadow: none;
`;

export const Container = styled.div`
  margin: 10px 10px;
  height: 100%;
`;


export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /*centers items on the line (the x-axis by default)*/
  align-items: center;
  background: linear-gradient(157deg, rgba(250,251,252,1) 0%, rgba(232,237,243,1) 100%);
  border-radius: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -15px;
`;

export const Layout = styled(ALayout)`
  height: 98.5vh;
  background: white;
  justify-content: center;
  align-items: stretch;
`;

export const Button = styled(AButton)`
  width: 100%;
  padding: 12px;
  font-size: 16px;
  height: auto;
  border-radius: 8px;
`

export const GhostButton = styled(AButton)`
  margin-top: -20px;
  padding: 0;
  font-weight: 500;
`

export const Prefix = styled.div`
  color: black;
  float: left;
  font-weight: 400;
  margin-right: 5px;
`;

export const LinkButton = ({
  children,
  prefix,
  to,
  align
}) => (
  <Link to={to} style={{display: 'inline-block', float: align || 'right'}}>
    <FormItem style={{marginBottom: 7}}>
      <GhostButton type="link">
        <Prefix>{prefix}</Prefix>{children}
      </GhostButton>
    </FormItem>
  </Link>
)

export const FormLayout = ({
  children,
  title,
  subtitle
}) => (
  <Layout>
    <Container>
      <Row style={{ height: '100%' }}>
        <Col style={{ height: '100%' }} span={10}>
          <Content>
            <FormWrapper>
              <Logo />
              <Title>{title}</Title>
              <Text type='secondary'>{subtitle}</Text>
              
              {children}
            </FormWrapper>
          </Content>
        </Col>
        <Col style={{ height: '100%' }} span={14}>
          <ImageContainer />
        </Col>
      </Row>
    </Container>
  </Layout>
)
