import styled from 'styled-components';
import { Typography } from 'antd';
import Colors from '../../constants/Colors';

const TitleWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const StyledTitle = styled(Typography.Title)`
`;

const IconWrapper = styled.span`
  font-size: 24px;
  bottom: 2px !important;
  display: inline-block;
  position: relative;
  margin-right: 12px;
`

export const Title = ({ children, icon }) => (
  <TitleWrapper>
    <StyledTitle level={2}>
      <IconWrapper>
        {icon}
      </IconWrapper>
      {children}
    </StyledTitle>
  </TitleWrapper>
)
