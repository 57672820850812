import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd'
import { gray5 } from '../constants/Colors';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px; 
`;

const StyledPage = styled.div`
  border: 1px solid ${gray5};
  color: ${gray5};
  padding: 4px 8px; 
  border-radius: 5px;
  margin-right: 6px;
  margin-left: 6px;
`;

export default function SimplePagination({ page, onChange, disabled }) {

  const handlePrevious = () => {
    if (page === 1) return;
    onChange(page - 1);
  };

  const handleNext = () => {
    if (disabled) return;
    onChange(page + 1);
  };

  return (
    <StyledWrapper>
      <Button onClick={handlePrevious}>Previous</Button>
      <StyledPage>{page}</StyledPage>
      <Button onClick={handleNext}>Next</Button>
    </StyledWrapper>
  );
}
