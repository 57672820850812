import React, { useState } from 'react';
import styled from 'styled-components';
import { Space, Button } from 'antd';
import { PlusOutlined, KeyOutlined } from '@ant-design/icons';

import { notify } from '../lib/toasts'

import { useKeys, useCreateKey, useRemoveKey } from '../features/keys/api/Keys';
import KeyForm from '../features/keys/components/KeyForm';
import KeyPreview from '../features/keys/components/KeyPreview';
import KeyList from '../features/keys/components/KeyList';
import { Title } from '../components/layout/Title';
import { Card } from '../components/layout/Card';

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin-bottom: -10px;
`;

const KeysPage = () => {

  const [displayLoadingForId, setDisplayLoadingForId] = useState(false);

  const [displayCreatedPreview, setDisplayCreatedPreview] = useState(null);
  const [displayCreateForm, setDisplayCreateForm] = useState(false);
  const [displayCreateLoading, setDisplayCreateLoading] = useState(false);
  const [page, setPage] = useState(1);

  const { isLoading, data } = useKeys({ page, limit: 25 });
  const createKey = useCreateKey();
  const removeKey = useRemoveKey();

  if (isLoading) return <h1>Loading</h1>

  const handleCreate = (payload) => {

    setDisplayCreateLoading(true)

    createKey.mutate(      
      { ...payload },
      {
        onSuccess: (data) => {
          setDisplayCreateLoading(false)
          setDisplayCreateForm(false)
          setDisplayCreatedPreview(JSON.parse(atob(data)))
        },
        onError: () => {
          setDisplayCreateLoading(false)
        },
      }
    );
  }

  const handleDelete = (id) => {

    setDisplayLoadingForId(id)

    removeKey.mutate(
      id,
      {
        onSuccess: (data) => {
          setDisplayLoadingForId(null)
          notify.success('Api key removed successfully')
        },
        onError: () => {
          setDisplayLoadingForId(null)
        },
      }
    );
   
  }

  const PreviewNode = !displayCreatedPreview ? null : (
    <KeyPreview 
      item={displayCreatedPreview} 
      onDismiss={() => setDisplayCreatedPreview(null)}
    />
  );
 
  const CreateNode = !displayCreateForm ? null : (
    <Card size="small">
      <KeyForm 
        submitLoading={displayCreateLoading}
        onSubmit={handleCreate}
        onCancel={() => setDisplayCreateForm(false)}
      />
    </Card>
  );
    
  return (
    <div>
      <Space direction="vertical" style={{display: 'flex'}}>
        <StyledHeader>
          <Title icon={<KeyOutlined />}>
            Keys
          </Title>
          {displayCreateForm ? null : (
            <StyledButton 
              type="primary" 
              shape="round" 
              size="large" 
              onClick={() => setDisplayCreateForm(true)}
              icon={<PlusOutlined />} 
              loading={displayCreateLoading}
            >
               Add Key
            </StyledButton>
          )}
        </StyledHeader>
        {PreviewNode}
        {CreateNode}
        <Card>
          <KeyList
            items={data?.data}
            loadingItemId={displayLoadingForId}
            onDelete={handleDelete}
            page={page}
            onPageChange={setPage}
          />
        </Card>
      </Space>
    </div>
  ); 
}

export default KeysPage;

