import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Space, Button, Modal, Input, Form, Radio  } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { notify } from '../../../lib/toasts'
import UserAccessTokenList from './UserAccessTokenList';

const StyledBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 100%;
`;

function UserAccessTokensModal({
  userIdentifier,
  isOpen = false,
  onClose,
}) {

  return (
    <Modal
      title="Access Tokens"
      width={800}
      open={isOpen}
      onCancel={onClose}
      footer={null}
    >
      <UserAccessTokenList {...{userIdentifier}}/>
    </Modal>
  )

  
}

export default UserAccessTokensModal;
