import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Space, Button, Modal, Input, Form, Radio  } from 'antd';
import { EditOutlined, FileOutlined } from '@ant-design/icons';

import { notify } from '../../../lib/toasts'

import { useUpdateDocument } from '../api/Documents';
import TemplateStages from '../../../constants/TemplateStages.js';

const StyledButton = styled(Button)`
  font-weight: 600;
`;

const StyledForm = styled(Form)`
  .ant-form-item-label {
    font-weight: 600;
  }
`

const StyledSpace = styled(Space)`
  padding: 20px 0 5px 0;
`

function DocumentOptionsModal({
  template,
  isOpen = false,
  onClose,
  onNavigate,
  onFill
}) {

  const navigate = useNavigate();
  const updateDocument = useUpdateDocument();

  const { identifier, name, stage } = template || {};

  if (!identifier) return null;

  const handleChange = (field, value) => {

    updateDocument.mutate(
      { identifier, payload: { [field]: value} },
      {
        onSuccess: (data) => {
          notify.success('Success');
        },
        onError: () => {
          notify.error('Oops.. update failed');
        },
      }
    ) 

  }



  return (
    <Modal
      title="Document Options"
      open={isOpen}
      footer={[
        <StyledButton 
          onClick={() => onNavigate(identifier)}
          icon={<EditOutlined />}
          type="primary"
          size="large"
        >
          Go To Editor
        </StyledButton>
      ]}
      onCancel={onClose}
    >
      <StyledSpace 
        direction="vertical"
        size="middle"
      >
        <StyledForm
          layout="vertical"
          size="large"
        >
          <StyledForm.Item label="Name">
            <Input 
              placeholder="Document A" 
              defaultValue={name}
              onBlur={(e) => {
                handleChange('name', e.target.value ? e.target.value : 'Document A')
              }}
            />
          </StyledForm.Item>
          <StyledForm.Item label="Stage">
            <Radio.Group
              value={stage}
              onChange={(e) => handleChange('stage', e.target.value)}
              optionType="button"
              buttonStyle="solid"
            >
              <Radio.Button value={TemplateStages.draft}>Draft</Radio.Button>
              <Radio.Button value={TemplateStages.published}>Published</Radio.Button>
            </Radio.Group>
          </StyledForm.Item>
        </StyledForm>
      </StyledSpace>
    </Modal>
  );
};

export default DocumentOptionsModal;
