import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Popconfirm, Space, Table, Button } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import Paginations from '../../../constants/Pagination';
import SimplePagination from '../../../components/SimplePagination';

const StyledActions = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const GroupList = ({
  loading = false,
  items = [],
  loadingItemId,
  submitLoading,
  onSelect,
  onEdit,
  onDelete,
  page = 1,
  onPageChange
}) => {

  const dataSource = items.map((item) => {
    return {
      key: item.identifier,
      id: item.identifier,
      title: item.title,
      createdOn: dayjs(item.createdOn).format('MM/DD/YYYY hh:mma')
    }
  });

  const handleChange = (changes) => {
    if (changes.current) onPageChange(changes.current)
  }

  return (
    <>
      <Table 
        size="middle"
        loading={loading}
        dataSource={dataSource} 
        onChange={handleChange}
        pagination={false}
        columns={[
          {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text, record, index) => {
              return text;
            }
          },
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => {
              return text;
            }
          },
          {
            title: 'Created',
            dataIndex: 'createdOn',
            key: 'createdOn',
            render: (text, record, index) => {
              return text;
            }
          },
          {
            title: '',
            dataIndex: 'actions',
            key: 'id',
            render: (id, record, index) => {
              return (
                <StyledActions>
                  <Space>
                    <Button 
                      shape="round" 
                      onClick={() => {
                        onSelect(record.id)
                      }}
                    >
                      View 
                    </Button>
                    <Button
                      shape="round"
                      onClick={() => {
                        onEdit(record.id)
                      }}
                      icon={<EditOutlined />}
                    >
                      Edit
                    </Button>
                    <Popconfirm
                      placement="topLeft"
                      title="Confirm Deletion"
                      description="This action cannot be undone."
                      onConfirm={() => onDelete(record.id)}
                      okText="Delete"
                      cancelText="Cancel"
                    >
                      <Button 
                        type="primary" 
                        shape="circle" 
                        ghost 
                        danger 
                        loading={loadingItemId === record.id}
                        icon={<DeleteOutlined />} 
                      />
                    </Popconfirm>
                  </Space>
                </StyledActions>
              );
            }
          }
        ]} 
      />
      <SimplePagination 
        page={page}
        onChange={onPageChange}
        disabled={items.length < Paginations.pageSize}
      />
    </>
  ); 
}

export default GroupList;
