import React from 'react';

import { FormLayout, Button, LinkButton, Form, FormItem, Input } from '../components/auth/Form';
import { useNavigate } from 'react-router-dom';
import { useLogin } from '../features/auth/api/Session';

import { notify } from '../lib/toasts'
import styled from 'styled-components';


const StyledSpan = styled.span`
  color: #adadad;
  font-size:16px;
  font-weight: normal;
`

const StyledLink = styled.a`
  color:#1677ff;
`
export default function LoginPage() {

  const navigate = useNavigate();
  const { mutate } = useLogin();

  const onFinish = (data) => {

    let nextData = {};
    let nextEmail = data.email ? data.email.trim() :'';
    let nextPassword = data.password ? data.password.trim():'';

    nextData.email = nextEmail;
    nextData.password = nextPassword;

    mutate(nextData, {
      onSuccess: (res) => {
        if (res && res.organization) {
          navigate('/templates')
        }
        else {
          notify.warning('This email account is associated with Joyfill Business. Please use a different email address');
        }

      },
      onError: (res) => {
        const error = res.response.data;
        notify.warning(error?.message);
      }
    });
  };

  return (
    <FormLayout
      title="Welcome back"
      subtitle="Please enter your details to login."
    >
      <Form
        layout="vertical"
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <FormItem
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <Input placeholder="Email" />
        </FormItem>

        <FormItem
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password placeholder="Password" />
        </FormItem>

        <FormItem>
          <LinkButton to="/register" align="left">
            Register new account
          </LinkButton>
          <LinkButton to="/forgotPassword">
            Forgot password?
          </LinkButton>
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit">
            Login
          </Button>
        </FormItem>
        <FormItem>
          <StyledSpan>By continuing, you agree to the <StyledLink href="https://joyfill.io/terms" target="_blank">Terms of Service</StyledLink> and <StyledLink href="https://joyfill.io/privacy-policy" target="_blank">Privacy Policy</StyledLink></StyledSpan>
        </FormItem>
      </Form>
    </FormLayout>
  );
};
