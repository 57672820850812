import React from 'react';
import styled from 'styled-components';
import { Alert, Space, Table, Card, Button } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

const StyledActions = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const KeyPreview = ({
  item,
  onDismiss
}) => {

  return (
    <Card size="small">
      <Space direction="vertical" style={{display: 'flex'}}>
        <Alert 
          style={{padding: 12}}
          type="info"
          showIcon
          message="Securely Save Your API Key"
          description="Important Note: This is the only time you will have access to preview your new secret key."
        />
        <Table
          size="small"
          pagination={false}
          dataSource={[{ 
            key: item._id,
            keyPublic: item.keyPublic,
            keySecret: item.keySecretOriginal,
          }]}
          columns={[
            {
              title: 'Public Key',
              dataIndex: 'keyPublic',
              key: 'keyPublic',
              render: (text, record, index) => {
                return text;
              }
            },
            {
              title: 'Secret Key',
              dataIndex: 'keySecret',
              key: 'keySecret',
              render: (text, record, index) => {
                return text;
              }
            },
          ]}
        />
        <StyledActions>
          <Button 
            type="primary"
            shape="round" 
            size="middle" 
            onClick={onDismiss}
          >
            Dismiss 
          </Button>
        </StyledActions>
      </Space>
    </Card>
  );
 
}

export default KeyPreview;

