import styled from 'styled-components';
import { Breadcrumb as ABreadcrumb } from 'antd';
import Colors from '../../constants/Colors';

const IconWrapper = styled.span`
  margin-right: 7px;
  position: relative;
  bottom: 2px;
  display: inline-block;

  .anticon {
    font-size: 16px !important;
  }
`
export const BreadcrumbItemIcon = ({ icon }) => (
  <IconWrapper>
    {icon}
  </IconWrapper>
)

export const Breadcrumb = styled(ABreadcrumb)`
  margin: 20px 0;
  font-size: 22px;
  font-weight: 500;
`;

export const BreadcrumbItem = styled(ABreadcrumb.Item)`

`;
