import React, { useState } from 'react';
import { useQuery } from '../../hooks/useQuery';
import { Spin } from 'antd';

import { JoyDoc } from '@joyfill/components';
import DocumentActionBar from '../documents/components/DocumentActionBar';
import { useDocument, useUpdateDocument } from '../documents/api/Documents';
import useDocumentFileUploader from '../documents/hooks/useDocumentFileUploader';
import { notify } from '../../lib/toasts'

/**
 * Emits a global message event to
 * our sdk-js package in-use per customers
 * 
 * @param {Object} data 
 * @returns Invoked message emission
 */
const globalEmit = (data) => {
  if (document.readyState !== 'complete') return;
  window.top.postMessage(data, "*");
}

const AuthError = () => {
  console.error('Joyfill Embed Error: Joyfill JS SDK requires a valid user access token.');

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, no valid access token.</p>
    </div>
  );
}

/**
 * Used as a standalone route to support the
 * sdk-js portal embed feature
 */
const EmbedDocument = () => {

  const query = useQuery();
  const userAccessToken = query.get("userAccessToken");
  const mode = query.get("mode") || 'edit';
  const documentIdentifier = query.get("documentIdentifier");
  const { isLoading, data } = useDocument(documentIdentifier, null, userAccessToken);

  const [docChanges, setDocChanges] = useState(null);

  const updateDocument = useUpdateDocument();
  const uploader = useDocumentFileUploader();

  const handleUpload = async ({ documentId, target }, fileUploads) => {
    const links = await uploader({ documentId, target }, fileUploads);
    globalEmit({ type: 'onUploaded', links });

    return links;
  }

  const handleDocumentUpdate = () => {
    updateDocument.mutate(      
      {
        identifier: documentIdentifier, 
        payload: docChanges,
        accessToken: userAccessToken
      },
      {
        onSuccess: (data) => {
          notify.success('Success');
          setDocChanges(null);
          globalEmit({ type: 'onSubmit', doc: data });
        },
        onError: (error) => {
          notify.error('Oops.. update failed');
          globalEmit({ type: 'onError', error: error });

        },
      }
    );
  }

  const handleDocumentChange = (params, changes, doc) => {
    globalEmit({ type: 'onChange', params, changes, doc })
    setDocChanges(doc);
  }

  return (
    <>
      {userAccessToken ? (
        <>
        <DocumentActionBar 
          display={!!docChanges}
          loading={updateDocument.isLoading}
          onSubmit={handleDocumentUpdate}
        />

        <Spin spinning={isLoading} delay={100} tip="Loading document...">
          <JoyDoc
            mode={mode}
            doc={data}
            userAccessToken={userAccessToken}
            onChange={handleDocumentChange}
            onUploadAsync={handleUpload}
          />
        </Spin>
        </>
      ) : (
        <AuthError />
      )}
    </>
  ); 
}

export default EmbedDocument;
