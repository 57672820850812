import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Space, Spin } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import { Breadcrumb, BreadcrumbItem, BreadcrumbItemIcon } from '../components/layout/Breadcrumb';
import { Card, CardBody } from '../components/layout/Card';

import { JoyDoc, getDefaultTemplate } from '@joyfill/components';

import { notify } from '../lib/toasts'
import DocumentTypes from '../constants/DocumentTypes';
import TemplateStages from '../constants/TemplateStages';

import { useCreateDocument } from '../features/documents/api/Documents';
import useDocumentFileUploader from '../features/documents/hooks/useDocumentFileUploader';
import DocumentActionBar from '../features/documents/components/DocumentActionBar';
import Prompt from '../hooks/useBlock';
import ReloadBlocker from '../components/ReloadBlocker';

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NewTemplatePage = () => {

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [doc, setDoc] = useState(getDefaultTemplate());
  const [hasChanges, setHasChanges] = useState(false)
  const { mutate: createDocument, isLoading } = useCreateDocument();
  const uploader = useDocumentFileUploader()

  const groupIdentifier = searchParams.get('group');
  const refIdentifier = searchParams.get('refId');

  const createSuccess = (data) => {

    notify.success('Success');
    let mode = refIdentifier ? 'fill' : 'edit';
    let successUrl = groupIdentifier
      ? `/documents/${data.identifier}?group=${groupIdentifier}&mode=${mode}`
      : `/documents/${data.identifier}?mode=${mode}`;
    navigate(successUrl);
  }

  const handleCreate = () => {
    setHasChanges(false);
    const nextDoc = { ...doc, type: DocumentTypes.template, stage: TemplateStages.draft };
    if (groupIdentifier) nextDoc.group = groupIdentifier;

    createDocument(nextDoc, {
      onSuccess: createSuccess,
      onError: () => notify.error('Oops.. create failed')
    });
  }

  const handleChange = (changelogs, doc) => {
    setHasChanges(true)
    setDoc(doc);
  }

  const BreadcrumbNode = groupIdentifier ? (
    <Breadcrumb>
      <BreadcrumbItem>
        <BreadcrumbItemIcon icon={<FileTextOutlined />} />
        <Link to={`/groups/${groupIdentifier}`}>
          Group Templates 
        </Link>
      </BreadcrumbItem>
      <BreadcrumbItem>
        {doc.name}
      </BreadcrumbItem>
    </Breadcrumb>
  ) : (
    <Breadcrumb>
      <BreadcrumbItem>
        <BreadcrumbItemIcon icon={<FileTextOutlined />} />
        <Link to="/templates">
          Templates 
        </Link>
      </BreadcrumbItem>
      <BreadcrumbItem>
        {doc.name}
      </BreadcrumbItem>
    </Breadcrumb>
  );

  const handleUpload = async (params, fileUploads) => {

    const { identifier, target } = params;

    const uploadResult = await uploader({ documentId: identifier, target }, fileUploads)

    return uploadResult;
  }

  return (
    <div>
      <ReloadBlocker shouldBlock={hasChanges} />
      <Space direction="vertical" style={{ display: 'flex' }}>
        <StyledHeader>
          {BreadcrumbNode}
        </StyledHeader>
      </Space>
      <Card>
        <Spin spinning={isLoading && refIdentifier} delay={100}>
          <CardBody>
            <DocumentActionBar display={!!doc} loading={isLoading} onSubmit={handleCreate} />
            <JoyDoc
              mode="edit"
              doc={doc}
              onChange={handleChange}
              onUploadAsync={handleUpload}
            />
          </CardBody>
        </Spin>
      </Card>
      <Prompt when={hasChanges} message='Changes that you made may not be saved.' />
    </div>
  );
}

export default NewTemplatePage;

