import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Popconfirm, Space, Button, List } from 'antd';

import { orange5 } from '../../../constants/Colors';
import SimplePagination from '../../../components/SimplePagination';

const StyledItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 8px;
`;

const StyledCurrent = styled.div`
  font-style: italic;
  color: ${orange5};
`;

const StyledActions = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;


const DocumentVersionList = ({
  current,
  activeId,
  loading = false,
  items = [],
  onPreview,
  onRestore,
  page = 1,
  onPageChange,
}) => {

  const nextItems = [{ ...current, current: true }, ...items];

  return (
    <>
      <List
        dataSource={nextItems}
        loading={loading}
        renderItem={(item) => (
          <List.Item bordered>
            <StyledItem>
              {!item.current ? null : <StyledCurrent>current</StyledCurrent>}
              <StyledTitle>{dayjs(item.createdOn).format('MM/DD/YYYY hh:mma')}</StyledTitle>
              <StyledActions>
                <Space>
                  <Button size="small" shape="round" onClick={() => onPreview(item)} >
                    Preview 
                  </Button>
                  {item.current ? null : (
                    <Popconfirm
                      title="Restore Confirmation"
                      description="This version will overwrite the current version. Are you sure you want to continue?"
                      onConfirm={() => onRestore(item)}
                      okText="Confirm"
                      cancelText="Cancel"
                    >
                      <Button size="small" shape="round" >
                        Restore 
                      </Button>
                    </Popconfirm>
                  )}
                </Space>
              </StyledActions>
            </StyledItem>
          </List.Item>
        )}
      />
      <SimplePagination
        page={page}
        onChange={onPageChange}
      />
    </>
  ); 
}

export default DocumentVersionList;
