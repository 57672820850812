/**
 * Colors: https://ant.design/docs/spec/colors
 */
export const gray1 = '#ffffff';
export const gray4 = '#f0f0f0';
export const gray5 = '#d9d9d9';
export const gray6 = '#bfbfbf';
export const gray7 = '#8c8c8c';

export const red5 = '#ff4d4f';

export const green6 = '#52c41a';

export const orange5 = '#ffa940';

export default {

  gray1,
  gray4,
  gray5,
  gray6,
  gray7,

  red5,

  green6,

  orange5,
  brandBlue: '#266FFA',

  layout: {
    grey: '#f6f7f8',
    border: '#ebebeb'
  }

}
