import styled from 'styled-components';
import { Card as ACard, Spin } from 'antd';
import Colors from '../../constants/Colors';

export const StyledCard = styled(ACard)`
  flex: 1;
  background: ${Colors.layout.grey};
  border-radius: 18px;

  .ant-table-wrapper {
    border-radius: 18px;
    border: 2px solid ${Colors.layout.border};
    background-color: white;
    padding: 15px;
  }

  .ant-table {
    td {
      padding: 18px 15px 18px 10px !important;
    }
  }
`;

export const CardBody = styled.div`
  padding: 14px;
  background: white;
  border-radius: 18px;
`;

export const Card = ({ children, loading }) => (
  <StyledCard bodyStyle={{ padding: 15 }}>
    {loading ? <Spin /> : children}
  </StyledCard>
)
