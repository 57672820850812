import { JoyDocExporter } from '@joyfill/components';

function ExportPage() {

  if (!window.exportData) return <h1>Invalid Export Data</h1>;

  return (
    <JoyDocExporter {...window.exportData} />
  );

}

export default ExportPage;
