import React from 'react';

import { FormLayout, Button, Form, FormItem, Input } from '../components/auth/Form';
import { useCreatePasswordRecoveryToken } from '../features/users/api/Users';

import { notify } from '../lib/toasts'

export default function ForgotPasswordPage() {

  const { mutate } = useCreatePasswordRecoveryToken();

  const onFinish = (data) => {

    mutate(
      { payload: { email: data.email } },
      {
        onSuccess: (response) => {
          if (response[0]
            && response[0].email
            && response[0].status === 'sent') {
            notify.success('Success')
          }

        },
        onError: (error) => {
          if(error){
            notify.error('Oops, Something went wrong. Please try again!')
          }
        }
      });
  };

  return (
    <FormLayout 
      title="Forgot your password?"
      subtitle="Enter email address below and if we find an email that matches the one you provided,
      you'll receive a forgot password link shortly.
      If you never receive it,
      it's likely you don't already have an account or it's under a different email address"
      onFinish={onFinish}
    >
      <Form
        layout="vertical"
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <FormItem
          name="email"
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <Input placeholder="Email" />
        </FormItem>

        <FormItem>
          <Button type="primary" htmlType="submit">
            Send me forgot password link
          </Button>
        </FormItem>
      </Form>
    </FormLayout>
  );
};
