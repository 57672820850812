import { useMutation } from '@tanstack/react-query';
import { post } from '../../../lib/axios';

export const useGenerateEmbed = () => {
  return useMutation({
    mutationFn: (payload) => post('/v1/webhooks/embed', payload),
  });

};

export const useCreateApplication = () => {
  return useMutation({
    mutationFn: (payload) => post('/v1/webhooks', payload),
  });

};

const exports = {
  useGenerateEmbed,
  useCreateApplication,
};

export default exports;

