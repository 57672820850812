import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { SettingOutlined } from '@ant-design/icons'
import JoyfillIcon from './JoyfillIcon'

import { Layout, Menu as AMenu } from 'antd';
const AHeader = Layout.Header;

export const StyledMenu = styled(AMenu)`
`

export const Header = styled(AHeader)`
  .logo {
    display: inline-block;
    float: left;
    padding: 7px 18px 7px 33px
  }
  .logo svg {
    display: inline-block;
    width: 28px;
    height: 26px;
  }

  ul.ant-menu .last-item {
    position: absolute;
    right: 25px;
    top: 10px;
  }

  .logo-item {
    padding-right: 0;
    padding-left: 0;
  }

  .ant-menu-horizontal {
    border-bottom: 1px solid rgb(211 213 218) !important;
  }
`

export const Label = styled.label`
  font-size: 16px;
`;

export const LinkLabel = styled(Link)`
  font-size: 16px;
`;

const Logo = () => (
  <div className="logo"><JoyfillIcon /></div>
)

export const Menu = ({ onLogout }) => {

  const location = useLocation();

  const getNextCurrentKey = (location) => {

    const isTemplates = location.pathname.search('template') !== -1;
    const isDocuments = location.pathname.search('document') !== -1;
    const isGroups = location.pathname.search('group') !== -1;

    if (isGroups) return 'groups'
    else if (isTemplates) return 'templates'
    else if (isDocuments) return 'documents'

  }

  const [current, setCurrent] = useState(getNextCurrentKey(location));

  useEffect(() => {

    if (location) {
      const nextCurrent = getNextCurrentKey(location);

      if(current !== nextCurrent ) {
        setCurrent(nextCurrent);
      }

    }
  }, [location]);

  function handleClick(e) {
    setCurrent(e.key);
  }

  return (
    <Header style={{background: 'white', padding: '0'}}>
      <StyledMenu
        theme="light"
        onClick={handleClick}
        mode="horizontal"
        selectedKeys={[current]}
        tabIndex={-1}
        items={[
          {
            label: (<LinkLabel tabIndex={-1} to="/templates"><Logo /></LinkLabel>),
            key: 'home',
            className: 'logo-item',
          },
          {
            label: (<LinkLabel tabIndex={-1} to="/templates">Templates</LinkLabel>),
            key: 'templates',
          },
          {
            label: (<LinkLabel tabIndex={-1} to="/documents">Documents</LinkLabel>),
            key: 'documents',
          },
          {
            label: (<LinkLabel tabIndex={-1} to="/groups">Groups</LinkLabel>),
            key: 'groups',
          },
          {
            label: <Label tabIndex={-1}>Settings & Users</Label>,
            key: 'SubMenu',
            icon: <SettingOutlined />,
            className: 'last-item',
            children: [
              {
                label: (<LinkLabel to="/users">Manage Users</LinkLabel>),
                key: 'users',
              },
              {
                label: (<LinkLabel to="/api_keys">API Keys</LinkLabel>),
                key: 'apiKeys',
              },
              {
                label: (<LinkLabel to="/webhooks">Webhooks</LinkLabel>),
                key: 'webhooks',
              },
              {
                label: <Label tabIndex={-1}>Logout</Label>,
                key: 'logout',
                onClick: () => {
                  onLogout()
                }
              }
            ],
          },
        ]}
      />
    </Header>
  );
}
