import React, { useState } from 'react';
import styled from 'styled-components';
import { Dropdown, Modal, Space, Button, Input, Radio, Spin, Row, Col, Tooltip } from 'antd';
import { SearchOutlined, PlusOutlined, FileTextOutlined, QuestionCircleTwoTone, FileOutlined, FileSearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { getDocumentFromTemplate } from '@joyfill/components';

import { Title } from '../components/layout/Title';
import { Card } from '../components/layout/Card';
import { useCreateDocument, useDocuments, useRemoveDocument } from '../features/documents/api/Documents';
import DocumentsList from '../features/documents/components/DocumentsList';
import JoyfillTemplatesList from '../features/documents/components/JoyfillTemplatesList';
import DocumentOptionsModal from '../features/documents/components/DocumentOptionsModal';
import DocumentTypes from '../constants/DocumentTypes';
import Paginations from '../constants/Pagination';
import normalizeArray from '../utils/normalizeArray';
import { notify } from '../lib/toasts';

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledActionMenu = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledDropdown = styled(Dropdown)`
  margin-bottom: -10px;
  float: right;
`;

const StyledSearchInput = styled(Input)`
  width: 400px;
`;

/**
 * This page is used for both the template list and document list.
 */
const DocumentsPage = ({ type }) => {

  const navigate = useNavigate();

  const [displayJoyfillTemplates, setDisplayJoyfillTemplates] = useState(false);
  const [displayLoadingForId, setDisplayLoadingForId] = useState(false);
  const [activeDocumentId, setActiveDocumentId] = useState(false);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  const docParams = {
    page,
    limit: Paginations.pageSize,
    type,
    stage: 'all'
  };

  if (searchQuery) docParams.name = { '$regex': searchQuery, '$options': 'i' };

  const {
    isLoading,
    isRefetching,
    data,
    refetch
  } = useDocuments(docParams, { keepPreviousData: true });

  const removeDocument = useRemoveDocument();

  const { mutate: createDocument } = useCreateDocument();

  const templatesLookup = normalizeArray('identifier', data?.data || []);

  const handleSelect = (id) => {
    setActiveDocumentId(id);
  };

  const handleCreate = () => navigate('/template');

  const handleDelete = (id) => {

    setDisplayLoadingForId(id)

    removeDocument.mutate(
      id,
      {
        onSuccess: (data) => {
          setDisplayLoadingForId(null)
        },
        onError: () => {
          setDisplayLoadingForId(null)
        },
      }
    );

  }

  /**
   * Fill Out: Creates a document from a template
   */
  const handleFilloutCreate = (templateIdentifer) => {
    createDocument(getDocumentFromTemplate(templatesLookup[templateIdentifer]), {
      onSuccess: (data) => {
        notify.success('Success');
        let successUrl = `/documents/${data.identifier}?mode=fill`;
        navigate(successUrl);
      },
      onError: () => notify.error('Oops.. create failed')
    });
  }

  let searchTimeout = null;

  return (
    <div>
      <Space direction="vertical" style={{ display: 'flex' }}>
        <StyledHeader>
          <Title icon={<FileTextOutlined />}>
            {type === DocumentTypes.document ? 'Documents' : 'Templates'}
            <Tooltip title="Click icon to learn the difference between templates and documents">
              <Button
                shape="circle"
                type="link"
                href='https://docs.joyfill.io/docs/key-terminology#template-vs-document'
                target="blank"
                icon={<QuestionCircleTwoTone style={{ fontSize: 17 }} />}
              />
            </Tooltip>
          </Title>
        </StyledHeader>
        <DocumentOptionsModal
          isOpen={activeDocumentId}
          template={templatesLookup[activeDocumentId]}
          onClose={() => setActiveDocumentId(null)}
          onNavigate={(identifier) => navigate(`/${type}s/${identifier}`)}
          onFill={handleFilloutCreate}
        />
        <Row>
          <Col span={12}>
            <StyledActionMenu>
              <Space>  
                <StyledSearchInput 
                  size="large" 
                  addonBefore={<SearchOutlined />} 
                  placeholder="Search name" 
                  onChange={(e) => {
                    if (searchTimeout) clearTimeout(searchTimeout);
                    searchTimeout = setTimeout(() => {
                      setSearchQuery(e.target.value.trim());
                    }, 500)
                  }}
                />
              </Space>
            </StyledActionMenu>
          </Col>
          <Col span={12}>
            {type !== DocumentTypes.template ? null : (
              <StyledDropdown 
                menu={{ 
                  items: [
                    {label: 'Blank', key: 'blank', icon: <FileOutlined/>}, 
                    {label: 'Joyfill Templates', key: 'joyfill', icon: <FileSearchOutlined />}
                  ],
                  onClick: (e) => {
                    if (e.key === 'blank') handleCreate();
                    else if (e.key === 'joyfill') setDisplayJoyfillTemplates(true);
                  }
                }}
              >
                <Button
                  type="primary"
                  size="large"
                >
                  Add Template
                  <PlusOutlined />
                </Button>
              </StyledDropdown>
            )}
          </Col>
        </Row>
        <Card>
          <Spin spinning={isLoading || isRefetching} delay={100}>
            <DocumentsList
              items={data?.data}
              loadingItemId={displayLoadingForId}
              filter={type}
              page={page}
              onPageChange={setPage}
              onEdit={handleSelect}
              onView={(identifier) => navigate(`/documents/${identifier}?mode=fill`)}
              onDelete={handleDelete}
              onFill={handleFilloutCreate}
            />
          </Spin>
        </Card>
      </Space>
      <Modal 
        title="Joyfill Templates Library" 
        open={displayJoyfillTemplates}
        footer={null}
        width="60%"
        onCancel={() => setDisplayJoyfillTemplates(false)}
      >
        <JoyfillTemplatesList />
      </Modal>
    </div>
  );


}


export default DocumentsPage;

