import styled from 'styled-components';
import { Typography } from 'antd';

export const Title = styled(Typography.Title)`
  margin-bottom: 12px !important;
  margin-top: 5px;
`;

export const Text = styled(Typography.Text)`
  opacity: .8;
  font-size: 15px;
`;
