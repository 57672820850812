import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { FormLayout, Button, Form, FormItem, Input, LinkButton} from '../components/auth/Form';
import { Card } from 'antd'

import { useUpdatePasswordFromRecoveryToken } from '../features/users/api/Users';


import { notify } from '../lib/toasts';

import qs from 'query-string';

const StyledText = styled.p`
  font-size: 20px;
`

export default function ResetPasswordPage() {

  const { mutate } = useUpdatePasswordFromRecoveryToken();

  useEffect(() => {

    if (window && window.location && window.location.search) {
      const { email, recoveryToken } = qs.parse(window.location.search);

      setEmail(email);
      setRecoveryToken(recoveryToken);

    }



  },[])

  const [email, setEmail] = useState(false);
  const [recoveryToken, setRecoveryToken] = useState(false);
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);

  const pageTitle = passwordResetSuccess ? "Password has been reset" : "Reset your password"

  const onFinish = (data) => {

    mutate(
      {
        passwordRecoveryToken: recoveryToken,
        payload: { email: email, newPassword: data.password }
      },
      {
        onSuccess: (response) => {
          if (response[0]
            && response[0].email
            && response[0].status === 'sent') {
            notify.success('Success')
            setPasswordResetSuccess(true)
          }
        },
        onError: (error) => {
          if (error) {
            notify.error('Oops, Smething went wrong. Please try again!')
          }
        }
      });
  };

  const getResetPasswordForm = () => (
    <div>
       <FormItem
          label="Password"
          name="password"
          rules={[
            { required: true, message: '*Password can not be blank', },
            { min: 8, message: 'Password should be 8 characters long' },
          ]}
        >
          <Input.Password
            placeholder="Password"
          />
        </FormItem>
        <FormItem
          label="Confirm Password"
          name="confirm password"
          dependencies={['password']}
          rules={[
            { required: true, message: '*Confirm password can not be blank'},
            ({ getFieldValue }) => ({
              
              validator(_, value) {
                
              let password = getFieldValue('password');
              let confirmedPassword = value;

                if (!password || !confirmedPassword || password === confirmedPassword) {
                  return Promise.resolve()
                }
                else if(confirmedPassword && confirmedPassword.length <8){
                  return Promise.reject(new Error('Password should be 8 characters long'))
                }
                else if (password && confirmedPassword && confirmedPassword.length >= 8 && confirmedPassword !== password) {
                  return Promise.reject(new Error('Passwords do not match!'));
                }

              },
            }),
          ]}
        >
          <Input.Password 
            placeholder="Confirm Password" 
           
          />
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit">
              Reset password
          </Button>
        </FormItem>
    </div>
  )

  const getPasswordResetSuccessNode = () => (
    <Card>
      <StyledText>Your password has been reset successfully.</StyledText>
      <LinkButton to="/login" align="left">
        Click here to go to login
      </LinkButton>
    </Card>
  )


  return (
    <FormLayout 
      title={pageTitle}
      onFinish={onFinish}
    >
      <Form
        layout="vertical"
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
       {passwordResetSuccess ? getPasswordResetSuccessNode() : getResetPasswordForm()}
      </Form>
    </FormLayout>
  );
};
