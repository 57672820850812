import { toast } from 'react-toastify';

const baseConfig = {
  position: "top-center",
  autoClose: 1500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: "light",
  progress: false,
}

const configed = (config = {}) => {
  return {...baseConfig, ...config}
}

const success = (title, addons) => {
  return toast.success(title || 'Success', configed(addons));
}

const error = (title, addons) => {
  return toast.error(title || 'Oops', configed(addons));
}

const warning = (title, addons) => {
  return toast.warning(title || 'Warning', configed(addons));
}

const info = (title, addons) => {
  return toast.info(title, configed(addons));
}

const plain = (title, addons) => {
  return toast(title, configed(addons));
}

export const notify = {
  success: (title, addons) => success(title, addons),
  error: (title, addons) => error(title, addons),
  warning: (title, addons) => warning(title, addons),
  info: (title, addons) => info(title, addons),
  plain: (title, addons) => plain(title, addons)
}
