import React, { useState } from 'react';
import styled from 'styled-components';
import { Input, Button, Icon, Select, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const StyledWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const StyledFormWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const StyledFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledFieldLabel = styled.label`
  font-weight: 500;
`;

const KeyForm = ({ 
  submitLoading,
  onSubmit, 
  onCancel, 
}) => {

  const [ form, setForm ] = useState({ type: 'test' });

  const handleChange = (field, value) => {
    const nextForm = {...form, [field]: value};
    setForm(nextForm);
  }

  const handleSubmit = () => {
    if (form.name) {
      onSubmit(form)
    }
  };

  return (
    <StyledWrapper>
      <StyledFormWrapper>
        <Space>
          <StyledFieldWrapper>
            <StyledFieldLabel>Type</StyledFieldLabel>
            <Select
              name="type"
              style={{width: '180px'}}
              value={form.type}
              options={[
                {
                  label: 'live',
                  value: 'live' 
                },
                {
                  label: 'test',
                  value: 'test' 
                }
              ]}
              onChange={(value) => handleChange('type', value)}
            />
          </StyledFieldWrapper>
          <StyledFieldWrapper>
            <StyledFieldLabel>Name</StyledFieldLabel>
            <Input 
              style={{width: '280px'}}
              value={form.name}
              onChange={(e) => handleChange('name', e.target.value)}
            />
          </StyledFieldWrapper>
        </Space>
      </StyledFormWrapper>
      <Space>
        <Button 
          type="primary" 
          shape="round" 
          size="middle" 
          disabled={submitLoading || !form.name}
          loading={submitLoading}
          onClick={() => {
            onSubmit(form);
          }}
        >
          Create 
        </Button>
        <Button 
          shape="round" 
          onClick={onCancel}
        >
          Cancel
        </Button>
      </Space>
    </StyledWrapper>
  );

}

export default KeyForm;
