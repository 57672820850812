import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { post, get, remove } from '../../../lib/axios';

/**
 * React-Query Keys
 */
const keys = {
  item: (id) => ['groups', id],
  items: (filters) => ['groups', filters]
};

/**
 * Hooks
 */
export const useGroups = (filters) => {
  return useQuery({
    queryKey: keys.items(filters),
    queryFn: () => get(`/v1/groups`, filters),
  });
};

export const useGroup = (id) => {
  return useQuery({
    queryKey: keys.item(id),
    queryFn: () => get(`/v1/groups/${id}`),
  });
};

export const useCreateGroup = () => {

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload) => post('/v1/groups', payload),
    onSuccess: (resource) => {
      queryClient.invalidateQueries(keys.items)
    }
  });

};

export const useUpdateGroup = () => {

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({identifier, payload}) => post(`/v1/groups/${identifier}`, payload),
    onSuccess: (resource) => {
      queryClient.invalidateQueries(keys.items)
    }
  });

};

export const useRemoveGroup = () => {

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (identifier) => remove(`/v1/groups/${identifier}`),
    onSuccess: () => {
      queryClient.invalidateQueries(keys.items)
    }
  });

};



export default {
  useGroup,
  useGroups,
  useCreateGroup,
  useUpdateGroup,
  useRemoveGroup
};

