import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { post, get, remove } from '../../../lib/axios';

/**
 * React-Query Keys
 */
const keys = {
  item: (identifier) => ['documents', identifier],
  items: (filters) => ['documents', filters.page, filters],
  joyfillTemplates: (filters) => ['joyfill_templates', filters.page, filters],
  versions: (identifier, filters) => ['document_versions', identifier, filters.page]
};

/**
 * Hooks
 */
export const useDocuments = (filters = {}, options = {}) => {
  return useQuery({
    queryKey: keys.items(filters),
    queryFn: () => {

      const { page, limit, type, stage, name } = filters;

      return name ? post(`/v1/documents/search`, { page, limit, type, query: { name }}) : get(`/v1/documents`, filters);

    },
    ...options
  });
};

export const useJoyfillTemplates = (filters = {}, options = {}) => {
  return useQuery({
    queryKey: keys.joyfillTemplates(filters),
    queryFn: () => {
      const { page, limit, query } = filters;
      return post(`/v1/joyfill/templates/search`, { page, limit, query });
    },
    ...options
  });
};

export const useDocumentVersions = (identifier, filters, options = {}) => {
  return useQuery({
    queryKey: keys.versions(identifier, filters),
    queryFn: () => get(`/v1/documents/${identifier}/versions`, filters),
    ...options
  });
};

export const useDocument = (identifier, query, accessToken) => {
  return useQuery({
    queryKey: keys.item(identifier),
    queryFn: () => get(`/v1/documents/${identifier}`, query, accessToken),
  });
};

export const useCreateDocument = () => {

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload) => post('/v1/documents', payload),
    onSuccess: (resource) => {
      queryClient.invalidateQueries(keys.items)
    }
  });

};

export const useUpdateDocument = (accessToken) => {

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({identifier, payload, accessToken}) => post(`/v1/documents/${identifier}`, payload, accessToken),
    onSuccess: (resource) => {
      queryClient.invalidateQueries(keys.items)
    }
  });

};

export const useRemoveDocument = () => {

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (identifier) => remove(`/v1/documents/${identifier}`),
    onSuccess: () => {
      queryClient.invalidateQueries(keys.items)
    }
  });

};

export const useTemplateDocumentSync = () => {

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ identifier }) => {

      const syncPage = async (page) => {

        const result = await post(`/v1/templates/${identifier}/documents`, { page, limit: 100 })

        const nextPage = page + 1; 

        if (nextPage > result.pages) return result;

        return await syncPage(nextPage);
        
      }

      return syncPage(1)

    },
    onSuccess: () => {
      queryClient.invalidateQueries(keys.items)
    }
  });

};

const exports = {
  useDocument,
  useDocuments,
  useCreateDocument,
  useUpdateDocument,
  useRemoveDocument,
  useTemplateDocumentSync
};

export default exports;
