import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Space, Button, Empty } from 'antd';
import { PlusOutlined, NotificationOutlined } from '@ant-design/icons';

import WebhookEmbed from '../features/webhooks/components/Embed';
import { useOrganization } from '../features/auth/api/Session';
import { useCreateApplication, useGenerateEmbed } from '../features/webhooks/api/Webhooks';
import { Title } from '../components/layout/Title';
import { StyledCard } from '../components/layout/Card';


const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin-bottom: -10px;
`;

/**
 * Uses the svix embed generator to create a new 
 * svix application and embed link
 * 
 * @returns Element
 */
const WebhooksPage = () => {

  const organization = useOrganization();
  const svixApplicationId = organization.data?.svixApplicationId;

  const { mutate: generateEmbed, isLoading: generateLoading, data: generateData, reset } = useGenerateEmbed();
  const { 
    mutate: createApplication, 
    isLoading: createLoading, 
    data: createData,
  } = useCreateApplication({
    onSuccess: (data) => {
      organization.refetch()
      generateEmbed()
    },
  });

  const svixEmbed = generateData?.embedLink;

  useEffect(() => {
    if (svixApplicationId || createData?.appId) generateEmbed()
  }, [svixApplicationId, createData])
    
  return (
    <div>
      <Space direction="vertical" style={{display: 'flex'}}>
        <StyledHeader>
          <Title icon={<NotificationOutlined />}>
            Webhooks
          </Title>
          {!svixApplicationId && <StyledButton
            type="primary"
            shape="round"
            size="large"
            onClick={() => createApplication({ name: organization.data?._id })}
            icon={<PlusOutlined />}
            loading={createLoading}
          >
            Enable Webhooks
          </StyledButton>}
        </StyledHeader>
        <Card 
          loading={generateLoading}
        >
          {!svixEmbed ? 
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Enabled Webhooks" 
            /> 
            : <iframe
                src={svixEmbed}
                style={{width: '100%', height: '100%', border: 'none'}}
                allow="clipboard-write"
                loading="lazy"
              >
          </iframe>}
        </Card>
      </Space>
    </div>
  ); 
}

const Card = styled(StyledCard)`
  min-height: 400px;
  height: 100%;
  width: 100%;
  min-width: 400px;

  .ant-card-body {
    height: 80vh;
    padding: 12px;
    background: #f8f9fd;
    border-radius: 18px;
  }
`

export default WebhooksPage;

