import React, { useState } from 'react';
import styled from 'styled-components';
import { Space, Button } from 'antd';
import { PlusOutlined, TeamOutlined } from '@ant-design/icons';

import { notify } from '../lib/toasts'

import PermissionRoles from '../constants/PermissionRoles';
import { useSession } from '../features/auth/api/Session';
import { useUsers, useCreateUser, useUpdateUser, useRemoveUser } from '../features/users/api/Users';
import UserForm from '../features/users/components/UserForm';
import UserList from '../features/users/components/UserList';
import { Title } from '../components/layout/Title';
import { Card } from '../components/layout/Card';

import UserAccessTokensModal from '../features/users/components/UserAccessTokensModal';

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin-bottom: -10px;
`;


const UsersPage = () => {

  const [editingId, setEditingId] = useState(null); //Should use identifier
  const [displayLoadingForId, setDisplayLoadingForId] = useState(false); //Should use identifier
  const [ viewAccessTokenUserId, setViewAccessTokenUserId] = useState(null);
  const [page, setPage] = useState(1);

  const [displayCreateForm, setDisplayCreateForm] = useState(false);
  const [displayCreateLoading, setDisplayCreateLoading] = useState(false);

  const { isLoading, data } = useUsers({ page, limit: 25, type: 'organization' }, { keepPreviousData: true });
  const createUser = useCreateUser();
  const updateUser = useUpdateUser();
  const removeUser = useRemoveUser();
  const session = useSession();

  if (isLoading) return <h1>Loading</h1>

  const handleCreate = (payload) => {

    setDisplayCreateLoading(true)

    createUser.mutate(
      { ...payload, role: payload.role || PermissionRoles.admin },
      {
        onSuccess: (data) => {
          setDisplayCreateLoading(false)
          setDisplayCreateForm(false)
        },
        onError: () => {
          notify.error('Oops.. Unable to create user!')
          setDisplayCreateLoading(false)
        },
      }
    );
  }

  const handleUpdate = (userIdentifier, payload) => {

    setDisplayLoadingForId(userIdentifier)

    updateUser.mutate(
      { id: userIdentifier, payload },
      {
        onSuccess: (data) => {
          setEditingId(null);
          setDisplayLoadingForId(null)
          notify.success('User updated')
        },
        onError: () => {
          setEditingId(null);
          setDisplayLoadingForId(null)
          notify.error('Oops.. Unable to update user!')
        },
      }
    );
  }

  const handleDelete = (user) => {

    if (session?.data._id === user.id) return;

    setDisplayLoadingForId(user.id)

    removeUser.mutate(
      user.identifier,
      {
        onSuccess: (data) => {
          notify.success('User removed successfully');
          setDisplayLoadingForId(null)
        },
        onError: () => {
          notify.error('Error removing member');
          setDisplayLoadingForId(null)
        },
      } 
    );

  }

  const CreateNode = !displayCreateForm ? null : (
    <Card size="small">
      <UserForm
        submitLoading={displayCreateLoading}
        onSubmit={handleCreate}
        onCancel={() => setDisplayCreateForm(false)}
      />
    </Card>
  );

  return (
    <Space direction="vertical" style={{ display: 'flex' }}>
      <StyledHeader>
        <Title icon={<TeamOutlined />}>
          Users
        </Title>
        {displayCreateForm ? null : (
          <StyledButton
            type="primary"
            shape="round"
            size="large"
            onClick={() => setDisplayCreateForm(true)}
            icon={<PlusOutlined />}
            loading={displayCreateLoading}
          >
            Add User
          </StyledButton>
        )}
      </StyledHeader>
      {CreateNode}
      <UserAccessTokensModal
        userIdentifier={viewAccessTokenUserId}
        isOpen={!!viewAccessTokenUserId}
        onClose={() => setViewAccessTokenUserId(null)}
      />
      <Card>
        <UserList
          sessionId={session?.data?._id}
          sessionItemId={session?.data?.identifier}
          activeItemId={editingId}
          items={data?.data}
          submitLoading={displayLoadingForId}
          onSubmit={handleUpdate}
          onSelect={setEditingId}
          onDelete={handleDelete}
          onTokenSelect={(id) => setViewAccessTokenUserId(id)}
          page={page}
          onPageChange={setPage}
        />
      </Card>
    </Space>
  );
}

export default UsersPage;
